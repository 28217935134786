/* Languange EN */

var enWallet = {
    "wallet.banner.history"       : "TRX HISTORY",
    "wallet.banner.title"         : "Have you linked all your income with us?",
    "wallet.banner.desc1"          : "Link your income from social media ads,",
    "wallet.banner.desc2"          : "influencer marketing platform,",
    "wallet.banner.desc3"          : "and e-commerce to your Creator Wallet",
     
    "wallet.banner.step1"         : "Copy your virtual wallet number to clipboard",
    "wallet.banner.step2-1"       : "Share or input ",
    "wallet.banner.step2-2"       : " account anywhere",
    "wallet.banner.step3"         : "Recieve money and collect your rewards!",
    "wallet.banner.stepTitle"     : "✨ How to use BintanGO Virtual Account",
  };
  
export default enWallet;