/* Languange EN */

var enPopUpLogin = {
    //General
    "popuLogin.formBottom.desc1"  : "Want to browse more in",
    "popuLogin.formBottom.desc2"  : "content?",

    //Mobile
    "popuLogin.mobile.loginTitle"  : "Login as a Fans",
    "popuLogin.mobile.regisTitle"  : "SignUp as a Fans",
  };
  
export default enPopUpLogin;