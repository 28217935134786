/* Languange EN */

var enBioMyLink = {
    
    // Galeri
    "bioMyLink.header.validation"            : "please input correct image URL",

    "myLink.edit"                          : "EDIT",
    "myLink.copy"                          : "COPY",
    "myLink.design"                        : "DESIGN",
    "myLink.mypage"                        : "MY PAGE",

    "myLink.changeUsername"                : "Change username",
    "myLink.usernameOnce"                  : "You can only change your username once",
    "myLink.usernameChange"                : "Your BintanGO username will be changed",
    "myLink.usernameChange2"               : "Your MyLink URL will be changed too",

  };
  
export default enBioMyLink;