/* Languange EN */

var enFAQ = {
    //Title
    "faq.page.title"            : "Help Center",

    "home.static1.desc"         : "Download the application to explore your idol celebs further and get interesting promos",
    "home.latestCeleb"          : "Latest Celebs",
  };
  
export default enFAQ;