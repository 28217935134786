/* Languange ID */

var enDashboard = {
    "dashboard.accountSettings"   : "Account Settings",
    "dashboardFans.myOrder"           : "My Order",
    "dashboardFans.myVideo"           : "My Video",
    "dashboardFans.wishlist"          : "Fan Club",
    "dashboardFans.creator"           : "Are you a creator?",
    "dashboardFans.regHere"           : "REGISTER HERE",

    "dashboardCeleb.amountof"         : "Amount of",
    "dashboardCeleb.myEarnings"       : "My Earning",
    "dashboardCeleb.balance"          : "Balance",
    "dashboardCeleb.balancewithdraw"  : "Balance & Withdrawal",
    "dashboardCeleb.bankaccount"      : "Bank Account",
    "dashboardCeleb.earnings"         : "My Earnings",
    "dashboardCeleb.services"         : "Services",
    "dashboardCeleb.home"             : "Home",
    "dashboardCeleb.socialInsight"    : "Social Insight",
    "dashboardCeleb.linkinbio"        : "My Link",
    "dashboardCeleb.invoiceHistory"   : "Invoice",
    "dashboardCeleb.finance"          : "Finance",
    "dashboardCeleb.brand"            : "Brand Deals",
    "dashboardCeleb.mission"          : "Creator Mission",
    "dashboardCeleb.comingsoon"       : "COMING SOON!",
    "dashboardCeleb.myTask"           : "My Task",
    "dashboardCeleb.experience"       : "Fan Experience",
    "dashboardCeleb.holidayMode"      : "Vacation Mode",
    "dashboardCeleb.fans"             : "Fan",
    "dashboardCeleb.ratings"          : "Ratings",
    "dashboardCeleb.points"           : "Points",
    "dashboardCeleb.review"           : "Review",
    "dashboardCeleb.workDelivery"     : "Execution Time",
    "dashboardCeleb.lifetimeEarnings" : "Lifetime Earning",
    "dashboardCeleb.lifetimeEarnings2": "Lifetime Earnings",
    "dashboardCeleb.days"             : "Day",
    "dashboardCeleb.withdraw"         : "WITHDRAW",
    "dashboardCeleb.info"             : "Your video call tasks are accessible on BintanGO apps",
    "dashboardCeleb.infoNew"          : "Try Our New Feature My Link!",
    "dashboardCeleb.VA.seeDetail"     : "See Detail",
    "dashboardCeleb.celebritySetting" : "Celebrity Setting",
    "dashboardCeleb.home.verified.title" : "Explore BintanGO Features",
    "dashboardCeleb.home.verified.desc" : "Start your journey with us and grow beyond. See what you can do, first 😉",
    "dashboardCeleb.home.verified.myLinkTitle" : "Build your portfolio",
    "dashboardCeleb.home.verified.myLinkDesc" : "Build your own portfolion on BintanGO With MyLink",
    "dashboardCeleb.home.verified.invoiceTitle" : "Send your first invoice",
    "dashboardCeleb.home.verified.invoiceDesc" : "Easily create and send your first invoice with BintanGO",
    "dashboardCeleb.home.verified.financeTitle" : "Apply Financing Now",
    "dashboardCeleb.home.verified.financeDesc" : "Say goodbye to waiting! Paid faster with us",
    "dashboardCeleb.home.verified.missionTitle" : "Claim your mission",
    "dashboardCeleb.home.verified.missionDesc" : "Complete missions, collect points and buy anything with points",
    
    "dashboardCeleb.home.basic.title" : "We’re so happy you’re part of us now!",
    "dashboardCeleb.home.basic.desc"  : "Thank you for being our creator. You can grow beyond more by becoming a verified creator!",

    "accountSettings.editProfile"     : "Edit Profile",
    "accountSettings.socialMedia"     : "Social Media",
    "accountSettings.changePassword"  : "Change Password",

    "accountSettingsSocialMedia.instagram": "INSTAGRAM USERNAME*",
    "accountSettingsSocialMedia.tiktok"   : "TIKTOK USERNAME",
    "accountSettingsSocialMedia.youtube"  : "YOUTUBE CHANNEL",
    "accountSettingsSocialMedia.twitter"  : "TWITTER USERNAME",

    "accountSettingsSocialMedia.titleNotValidIG"  : "We cannot find your IG",
    "accountSettingsSocialMedia.descNotValidIG"  : "It looks like your instagram is not a business account, you will miss some of our features. But don't worry, you can still continue to register as a creator",
    "accountSettingsSocialMedia.tryAgain"  : "TRY AGAIN",
    "accountSettingsSocialMedia.continue"  : "CONTINUE",

    "accountSettingsChangePassword.note"  : "We will send you a URL to reset your password. Make sure the email you input matches the one recorded for this account.",
    "accountSettingsChangePassword.email" : "EMAIL",
    "accountSettingsChangePassword.resetPassword" : "Reset Password",
    "accountSettingsChangePassword.sendLink" : "Send Link",

    "accountSettingsEditProfile.changepp" : "CHANGE PROFILE PICTURE",
    "accountSettingsEditProfile.fullname" : "FULLNAME",
    "accountSettingsEditProfile.username" : "USERNAME",
    "accountSettingsEditProfile.yourProvince" : "YOUR PROVINCE",
    "accountSettingsEditProfile.yourCity" : "YOUR CITY",
    "accountSettingsEditProfile.bio"      : "BIO",
    "accountSettingsEditProfile.gender"   : "GENDER",
    "accountSettingsEditProfile.man"      : "Male",
    "accountSettingsEditProfile.woman"    : "Female",
    "accountSettingsEditProfile.birthday" : "BIRTHDAY",
    "accountSettingsEditProfile.reset"    : "Reset",
    "accountSettingsEditProfile.save"     : "Save Changes",
    "accountSettingsEditProfile.savenback": "Save & back",
    "accountSettingsEditProfile.category" : "CATEGORY",
    "accountSettingsEditProfile.mainCategory" : "MAIN CATEGORY",
    "accountSettingsEditProfile.subCategory" : "SUB CATEGORY (MAX 3 CATEGORIES)",
    "accountSettingsEditProfile.phone" : "PHONE NUMBER",
    "accountSettingsEditProfile.note" : "Personal information cannot be seen by other user. If you want to change your email or phone number, please contact the customer service team via whatsapp to ",

    "accountSettingsEditProfile.selectCategory" : "Select Category",
    "accountSettingsEditProfile.selectSubcategory" : "Select Subcategory",
    "accountSettingsEditProfile.selectAll" : "Select All",
    "accountSettingsEditProfile.clearAll" : "CLEAR ALL",
    
    "myOrder.allOrder"                : "All Order",
    "myOrder.onProcess"               : "On Process",
    "myOrder.completed"               : "Completed",
    "myOrder.expired"                 : "Expired",
    "myOrder.rejected"                : "Rejected",
    "myOrder.titleDownloadBintango"   : "Want to see your order?",
    "myOrder.descDownloadBintango"    : "Please download the BintanGO application to see your order in detail",

    "myOrderDetail.orderDetail"       : "Order Detail",
    "myOrderDetail.orderHistory"      : "Order History",
    "myOrderDetail.requestBrief"      : "Your Instruction",
    "myOrderDetail.nextPayment"       : "Pay Now",
    "myOrderDetail.reviewRating"      : "Give a Review & Rating",

    "myVideo.allVideos"               : "All Videos",
    "myVideo.publicVideos"            : "Public Videos",
    "myVideo.privateVideos"           : "Private Videos",
    "myVideo.titleDownloadBintango"   : "Mau lihat video kamu?",
    "myVideo.descDownloadBintango"    : "Silahkan download aplikasi BintanGO untuk melihat video yang sudah kamu pesan",

    "rating.ratingReview"             : "Give Rating Review",
    "rating.review"                   : "REVIEW",
    "rating.reviewPlaceholder"        : "Tell your idol celeb that the gift he gave was very memorable for you",
    "rating.thankyou"                 : "Thankyou",

    "balance.withdrawal"              : "Withdrawal",
    "balance.custom"                  : "Custom",
    "balance.today"                   : "Today",
    "balance.yesterday"               : "Yesterday",
    "balance.thisWeek"                : "This Week",
    "balance.thisMonth"               : "This Month",
    "balance.7lastDay"                : "Last 7 Day",
    "balance.30lastDay"               : "Last 30 Day",
    "balance.allTransaction"          : "All Transaction",
    "balance.titleDownloadBintango"   : "Mau lihat saldo kamu?",
    "balance.descDownloadBintango"    : "Silahkan download aplikasi BintanGO untuk melihat saldo kamu secara detail",

    "experience.available"            : "Available",
    "experience.notAvailable"         : "Not Available",
    "experience.price"                : "PRICE",
    "experience.workDelivery"         : "DELIVERY TIME",
    "experience.day"                  : "Day",
    "experience.week"                 : "Week",

    "myTask.allStatus"                : "All Status",
    "myTask.inTheProcess"             : "In The Process",
    "myTask.completed"                : "Completed",
    "myTask.expiredrejected"          : "Expired/Rejected",
    "myTask.allService"               : "All Service",
    "myTask.vsh"                      : "Video Shoutout",
    "myTask.vca"                      : "Video Call",
    "myTask.allDate"                  : "All Date",
    "myTask.90lastDay"                : "Last 90 Days",
    "myTask.30lastDay"                : "Last 30 Days",
    "myTask.customDate"               : "Custom Date",
    "myTask.allTasks"                 : "All Task",
    "myTask.newTasks"                 : "New Task",
    "myTask.finishTasks"              : "Completed Task",
    "myTask.ongoingTasks"             : "Ongoing Task",
    "myTask.expired"                  : "Expired",
    "myTask.rejected"                 : "Rejected",
    "myTask.titleDownloadBintango"    : "Mau lihat tugas kamu?",
    "myTask.descDownloadBintango"     : "Silahkan download aplikasi BintanGO untuk melihat tugas kamu secara detail",

    "myTaskDetail.reject"             : "Reject",
    "myTaskDetail.accept"             : "Accept",
    "myTaskDetail.confirmAccept"      : "Are you sure you want to accept this task?",
    "myTaskDetail.confirmReject"      : "Are you sure you want to reject this task?",
    "myTaskDetail.reason"             : "Choose Reason",

    "earnings.project"                : "Business Project",

    "myLink.design"                   : "DESIGN",
    "myLink.mylink"                   : "MY LINK",

    "noData"                          : "No Data",
    "to"                              : "to",
    "DateTime"                        : "Date & Time",
    "dueDate"                         : "Due Date",
    "totalAmount"                     : "Total Amount",
    "transferAmount"                  : "Transfer Amount",
    "deductionFee"                    : "Deduction Fee",
    "totalPointEarned"                : "Total Point Earned",
    "Amount"                          : "Amount",
    "back"                            : "Back",
    "thankyou"                        : "Thankyou",
    "DownloadBintangoNow"             : "Download Now",
    "save"                            : "Save",
    "cancel"                          : "Cancel",
    "yes"                             : "Yes",
    "publicVideo"                     : "Public Video",
    "privateVideo"                    : "Private Video",
    "schedule"                        : "Video Call Schedule",
    "Pts"                             : "pts",
    "Points"                          : "points",
    "earningshistory"                 : "Earnings History",
    "transactionhistory"              : "Transaction History",
    "account"                         : "Account",
    "redeem"                          : "Redeem",
  };
  
export default enDashboard;