/* Languange EN */

var enPopUpNotifApps = {
    //General
    "popupNotifApps.form.button"   : "DOWNLOAD NOW",

    //VC
    "popupNotifApps.vc.title"    : "We'll send your request!",
    "popupNotifApps.vc.desc1"    : "We will reach out to ",
    "popupNotifApps.vc.desc2"    : " and let them know that you want to have a video call with them. Download BintanGO app to book video call with our talents now!",

    //VSH
    "popupNotifApps.vsh.title"    : "We'll send your request!",
    "popupNotifApps.vsh.desc1"    : "We will reach out to ",
    "popupNotifApps.vsh.desc2"    : " and let them know that you want to order video shoutout. Download BintanGO app to book video shoutout seamlessly!",
  };
  
export default enPopUpNotifApps;