/* Languange EN */

var enInvoice = {
    "invoice"                   : "Invoice",
    "invoice.history"           : "Invoice History",
    "invoice.historyDesc"       : "Check and share your created invoice",
    "invoice.preview"           : "Preview",
    "invoice.create"            : "Create Invoice",
    "invoice.sent"              : "Invoice Sent",
    "invoice.awaitingPayment"   : "Awaiting Payment",
    "invoice.paid"              : "Invoice Paid",
    "invoice.createNew"         : "CREATE NEW INVOICE",
    "invoice.edit"              : "Edit",
    "invoice.download"          : "Download",
    "invoice.share"             : "Share",
    "invoice.createdDate"       : "Created Date",
    "invoice.dueDate"           : "Due Date",
    "invoice.applyForEarlyPay"  : "APPLY FOR EARLY PAY",
    "invoice.earlyPayApproved"  : "EARLY PAY APPROVED",

    "invoice.sort"              : "Sort",
    "invoice.sort.dateCreated"  : "Date created",
    "invoice.sort.dueDate"      : "Due date",
    "invoice.sort.clientName"   : "Client name",

    "invoice.filter"            : "Filter",
    "invoice.filter.draft"      : "Draft",
    "invoice.filter.awaitingPayment" : "Awaiting Payment",
    "invoice.filter.paid"       : "Paid",
    "invoice.filter.completed"  : "Completed",
    "invoice.filter.notPaid"    : "Not paid",
    "invoice.filter.expired"    : "Expired",

    "invoice.step1"             : "Step 1",
    "invoice.step2"             : "Step 2",
    "invoice.step3"             : "Step 3",
    "invoice.step4"             : "Step 4",
    "invoice.step5"             : "Step 5",
    "invoice.chooseTemplate"    : "Choose Template",
    "invoice.info"              : "Invoice Info",
    "invoice.clientInfo"        : "Client Information",
    "invoice.jobDescription"    : "Job Scope",
    "invoice.summary"           : "Summary",
};
  
export default enInvoice;