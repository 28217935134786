/* Languange EN */

var enMission = {
    "mission.creator"                   : "Creator Mission",
    "mission.backtoMissionList"         : "Back to mission list",
    "mission.backtoHome"                : "Back to Creator Home",

    "mission.start"                     : "START",
    "mission.claim"                     : "CLAIM",
    "mission.claimed"                   : "CLAIMED",
    "mission.andGet"                    : "and get",

    "mission.titleMenuMission"          : "Play your mission, claim your passion",
    "mission.menuAvailable"             : "Available Mission",
    "mission.menuClaim"                 : "Claim your reward",

    "mission.nodataTitle.mission"       : "Whoops.. nothing to mission here",
    "mission.nodataDesc.mission"        : "You dont have a mission  completed yet. Let’s complete the mission",
    "mission.nodataTitle.claim"         : "Whoops.. nothing to claim here",
    "mission.nodataDesc.claim"          : "You dont have a mission  completed yet. Let’s complete the mission",

    //Detail
    "mission.detail.intro"              : "Your Mission",
    "mission.detail.introDesc"          : "What you should do",
    "mission.detail.introPoint"         : "You will Get",
    "mission.detail.claim"              : "CLAIM REWARD NOW",
    "mission.detail.claimed"            : "REWARD ALREADY CLAIMED",
    "mission.detail.start"              : "START THE MISSION",
    "mission.detail.backHistory"        : "BACK TO MISSION LIST",
    "mission.detail.backHome"           : "BACK TO HOME",
    "mission.detail.popUpTitle"         : "Congratulations!",
    "mission.detail.popUpDesc"          : "You finish the mission and get points. Collect more points so you can exchange it with a lot of choices in BintanGO",
};
  
export default enMission;