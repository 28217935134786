/* Languange EN */

var enPopUpPurchaseStep = {
    //Video Shootout
    "popuPurchaseStep.vs.title"      : "How to Order ?",
    "popuPurchaseStep.vs.step1"      : "Choose who this video is for, whether for yourself, or someone else.",
    "popuPurchaseStep.vs.step2"      : "Choose the right event for the shoutout video you requested",
    "popuPurchaseStep.vs.step3"      : "Write instructions to your idol about what they should say in the shoutout video.",
    "popuPurchaseStep.vs.step4"      : "Make sure the order and payment info is in accordance with what you wrote earlier.",
    "popuPurchaseStep.vs.step5"      : "Pay with the payment method you want. ",
    "popuPurchaseStep.vs.step6"      : "Once the payment is successful, the system will respond that the payment status has been received.",
    "popuPurchaseStep.vs.step7"      : "Once your request is complete, we'll send you a notification so you can see, share, or download the video.",

    //Video Call
    "popuPurchaseStep.vc.title"      : "How to Order ?",
    "popuPurchaseStep.vc.step1"      : "Answer the question from your idol to make the call more exciting",
    "popuPurchaseStep.vc.step2"      : "Select available schedule",
    "popuPurchaseStep.vc.step3"      : "Make payment",
    "popuPurchaseStep.vc.step4"      : "Receive confirmation from your idol",
    "popuPurchaseStep.vc.step5"      : "You must download BintanGO App to do the video call",
    "popuPurchaseStep.vc.step6"      : "Add the schedule to your calendar",
    "popuPurchaseStep.vc.step7"      : "Join the video call session on time",
    "popuPurchaseStep.vc.step8"      : "Wait for your idol in the video call waiting room, maximum 5 minutes",
    "popuPurchaseStep.vc.step9"      : "Jump in to the video call with your idol within the call duration",
  };
  
export default enPopUpPurchaseStep;