/* Languange ID */

var idFinance = {
    "finance"                   : "Finance",
    "finance.backToHome"        : "Kembali ke Halaman Creator",
    "finance.create"            : "Creator financing",
    "finance.earlyPay"          : "Early Pay",
    "finance.celebFinancing"    : "Celeb Financing",
    "finance.info"              : "Informasi Financing",
    "finance.headerContentTitle": "Masih mau membuang waktumu?",
    "finance.headerContentDesc" : "Gunakan fitur financing kami untuk mendapatkan pembayaran secara cepat",
    "finance.headerContentTitleEarlyPay": "No more waiting time from your clients ⌛️",
    "finance.headerContentDescEarlyPay": "Tidak perlu ribet, pastikan saja kamu mempunyai dokumen ini!",
    "finance.existTransactionTitle": "Total Dana Cair",
    "finance.existTransactionDesc": "Berapa banyak uang yang sudah kamu terima lebih cepat dari klien kamu janjikan",
    "finance.financingContract" : "kontrak financing",
    "finance.seeAllTransaction" : "Lihat semua transaksi",
    "finance.reqNewFinancing"   : "Ajukan permintaan financing",
    "finance.loanAmount"        : "Jumlah Pinjaman",
    "finance.howToPay"          : "Cara Bayar",
    "finance.repaymentMethod"   : "Metode Pembayaran Pinjaman",
    "finance.dueDate"           : "Tenggat Waktu",
    "finance.monthTenor"        : "Bulan Tenor",
    "finance.daysTenor"         : "Hari Tenor",
    "finance.submissionDate"    : "Submission Date",
    "finance.rejectedDate"      : "Tanggal Penolakan",
    "finance.reason"            : "Alasan",
    "finance.paymentFinish"     : "Pembayaran Selesai",
    "finance.noData"            : "Tidak ada Data",
    "finance.copy"              : "SALIN",

    "finance.howtopayWallet"    : "Cara melakukan pembayaran dengan BintanGO Wallet",
    "finance.titleHowtopayWallet": "BintanGO Wallet",
    "finance.descHowtopayWallet": "Otomatis akan mengurangi saldo BintanGO kamu pada tenggat waktu pembayaran yang telah ditentukan<br/><br/>Pastikan saldo BintanGO kamu tercukupi untuk melakukan pembayaran ya!",
    "finance.howtopayVA"        : "Cara membayar dengan Virtual Account",
    "finance.titleHowtopayVA"   : "BCA Virtual Account",
    "finance.descHowtopayVA"    : "Bayar lewat Virtual account ketika tanggal pembayaran sudah datang",
    "finance.atm1"              : "Masukkan kartu di <b>Mesin ATM</b>",
    "finance.atm2"              : "Masukkan <b>6 Digit PIN</b>",
    "finance.atm3"              : "Pilih <b>Transaksi Lainnya</b> dan pilh lagi <b>Transfer</b>",
    "finance.atm4"              : "Kemudian pilih <b>BCA Virtual Account</b> dan masukkan <b>Nomor BCA Virtual Account</b>",
    "finance.atm5"              : "Masukkan <b>jumlah peminjaman</b> dan pastikan angka yang dimasukkan sudah benar",
    "finance.atm6"              : "Klik <b>Ya</b>, dan pembayaran kamu akan selesai",
    "finance.mobile1"           : "Please <b>login</b> to the <b>BCA Mobile application</b>",
    "finance.mobile2"           : "Select <b>m-Transfer</b>",
    "finance.mobile3"           : "Continue to <b>BCA Virtual Account</b> and  input the <b>BCA Virtual Account Number</b>",
    "finance.mobile4"           : "Input the <b>ammount of the loans</b> and make sure the transaction details are correct",
    "finance.mobile5"           : "Enter your <b>m-BCA Pin</b> and your transaction has been completed",

    "finance.sort"              : "Urutkan",
    "finance.sort.loanAmount"   : "Loan Amount",
    "finance.sort.lastUpdate"   : "Last Update",

    "finance.status"            : "Status",
    "finance.status.ongoing"    : "On Going",
    "finance.status.waiting"    : "Waiting",
    "finance.status.rejected"   : "Rejected",
    "finance.status.paymentFailed" : "Payment Failed",
    "finance.status.finished"   : "Finished",

    "finance.become"            : "Fitur ini khusus untuk Verified Creators BintanGO! Ayo gabung sekarang!",
    "finance.becomeOneTitle"    : "Akses tanpa batas",
    "finance.becomeOneDesc"     : "Dapatkan akses ke semua fitur Creator, seperti Invoice, Creator Mission, Financing, MyLink untuk memaksimalkan potensi kamu menjadi bintang!",
    "finance.becomeTwoTitle"    : "Peluang lebih banyak!",
    "finance.becomeTwoDesc"     : "Membuka lebih banyak peluang dengan mendapat tawaran kerja sama dari brand-brand terkenal.",
    "finance.becomeThreeTitle"  : "Jadi yang pertama!",
    "finance.becomeThreeDesc"   : "Untuk mengetahui fitur Creator baru dari BintanGO. Kita janji akan menjadikan kamu yang pertama dan utama.",
    
    "finance.howOneTitle"       : "Isi data pendaftaran",
    "finance.howOneDesc"        : "Lengkapi data pada formulir pendaftaran",
    "finance.howTwoTitle"       : "Buat atau pilih invoice",
    "finance.howTwoDesc"        : "Kamu bisa membuat invoice BintanGO baru atau memilih invoice yang sudah kamu miliki. Kamu dapat mengajukan financing sampai 80% dari jumlah pembayaran invoice.",
    "finance.howThreeTitle"     : "Tunggu hasilnya",
    "finance.howThreeDesc"      : "Tunggu hasilnya dalam 1 sampai 3 hari kerja apakah pendaftaran kamu diterima atau tidak.",
    "finance.howFourTitle"      : "Terima Uang di Saldo Kreator",
    "finance.howFourDesc"       : "Ketika diterima, kamu akan menerima uang langsung di saldo kreator. Paling lama hanya 5 hari kerja, kamu tidak perlu menunggu berbulan-bulan.",
    "finance.howFiveTitle"      : "Bayar kembali tepat waktu",
    "finance.howFiveDesc"       : "Klien sudah melunasi pembayaran? Bayar kembali financing nya dan dapatkan job lainnya!",

    "finance.whyOneTitle"       : "Pembayaran yang lebih cepat",
    "finance.whyOneDesc"        : "Pencairan invoice lebih cepat tanpa perlu menunggu waktu kontrak",
    "finance.whyTwoTitle"       : "<30 detik untuk klaim",
    "finance.whyTwoDesc"        : "Sangat mudah untuk mengklaim pembiayaan dengan proses sangat cepat",
    "finance.whyThreeTitle"     : "Dijamin pasti Aman!",
    "finance.whyThreeDesc"      : "Jaminan privasi aman dengan kerjasama langsung bersama partner yang telah diawasi oleh OJK",
    "finance.benefitsOneTitle"  : "Tidak perlu menunggu lagi",
    "finance.benefitsOneDesc"   : "Sekarang tidak perlu membuang-buang waktu lagi, karena kamu bisa dapatkan pembayaran dari invoicemu secara langsung",
    "finance.benefitsTwoTitle"  : "Akses untuk semua",
    "finance.benefitsTwoDesc"   : "Tidak perlu repot-repot daftar ulang lagi , cukup tiga langkah kamu sudah bisa menggaksesnya",
    "finance.benefitsThreeTitle": "Klaim banyak Invoice",
    "finance.benefitsThreeDesc" : "Tidak hanya 1/2/3 Invoice, tapi semua invoice bisa dicairkan secara bersamaan sekarang juga",
    "finance.why"               : "Kenapa",
    "finance.not"               : "Jangan",
    "finance.creatorFinancing"  : "Creator Financing",
    "finance.buttonVerify"      : "GABUNG SEKARANG",
    "finance.buttonApply"       : "DAFTAR SEKARANG",
    "finance.next"              : "SELANJUTNYA",
    "finance.prev"              : "Sebelumnya",
    "finance.ktp"               : "KTP",
    "finance.select"            : "SELECT",
    "finance.yourInvoice"       : "Invoice Kamu",
    "finance.benefits"          : "Benefits",
    "finance.how"               : "Cara Kerja",
    "finance.activity"          : "Peminjaman",
    "finance.finish"            : "Selesai",
    "finance.transaction"       : "Riwayat Financing",
    "finance.ActivityNoDataDesc": "Sepertinya kamu belum mempunyai transaksi selesai",

    "finance.oppss"             : "Ups..",
    "finance.createInvoiceFirstDesc" : "Sepertinya Kamu belum membuat invoice dari BintanGO! Ayo coba buat Invoice di sini!",
    "finance.createInvoiceFirstButton" : "BUAT INVOICE PERTAMA KAMU",

    //Step
    "finance.step1.navTitle"    : "Step 1",
    "finance.step1.navSubTitle" : "Isi data diri",
    "finance.step1.name"        : "NAMA LENGKAP *",
    "finance.step1.id"          : "NOMER KTP *",
    "finance.step1.email"       : "ALAMAT EMAIL *",
    "finance.step1.dob"         : "TANGGAL LAHIR *",
    "finance.step1.gender"      : "JENIS KELAMIN *",
    "finance.step1.phone"       : "NOMOR TELEPON *",
    "finance.step1.address"     : "ALAMAT SESUAI KTP *",
    "finance.step1.idcard"      : "UNGGAH KTP",
    "finance.step1.idcardPopUp" : "Unggah KTP",
    "finance.step1.step1PopUp"  : "Pastikan posisi kartu berada di posisi tengah frame dan data yang tertera terlihat dengan jelas",
    "finance.step1.step2PopUp"  : "Pastikan kartu identitas yang kamu unggah masih berlaku",
    "finance.step1.step3PopUp"  : "Pastikan kartu identitas kamu bukan hasil fotokopi atau scan",
    
    "finance.step2.navTitle"        : "Step 2",
    "finance.step2.navSubTitle"     : "Informasi Financing",
    "finance.step2.invAmt"          : "JUMLAH INVOICE *",
    "finance.step2.loanAmt"         : "JUMLAH PINJAMAN *",
    "finance.step2.tenor"           : "TENOR",
    "finance.step2.tenorSub"        : "Pastikan tenor yang kamu pilih lebih panjang dari batas pembayaran klien",
    "finance.step2.method"          : "LOAN REPAYMENT METHOD",
    "finance.step2.upload"          : "PILIH INVOICE",
    "finance.step2.seeSelect"       : "Lihat pilihan",
    "finance.step2.select1"         : "Kamu telah memilih ",
    "finance.step2.select2"         : " Invoice",
    "finance.step2.selected"        : "Invoice yang dipilih",
    "finance.step2.optVaTitle"      : "BintaGO Wallet",
    "finance.step2.optVaSub"        : "Automatically deduct the repayment amount from your BintanGO wallet on the due date.",
    "finance.step2.optWaTitle"      : "Virtual Account",
    "finance.step2.optWaSub"        : "Pay through Virtual Account when the repayment due date",
    "finance.step2.invPopupTitle"   : "Pilih Invoice",
    "finance.step2.invPopupSubTitle": "Hanya menampilkan invoice yang belum dibayar dengan Ketentuan Pembayaran dari 30 - 90 hari",
    "finance.step2.invPopupDesc"    : "You can select more than 1 invoice",

    "finance.step3.navTitle"    : "Step 3",
    "finance.step3.navSubTitle" : "Informasi Klien",
    "finance.step3.clientName"  : "NAMA KLIEN *",
    "finance.step3.contact"     : "NAMA PIC KLIEN *",
    "finance.step3.phone"       : "NO. TELP PIC",
    "finance.step3.email"       : "ALAMAT EMAIL *",

    "finance.success.title"     : "Pendaftaran kamu telah berhasil terkirim!",
    "finance.success.subtitle"  : "Kami akan menginformasikan ke kamu ketika pendaftaran kamu telah disetujui. Pastikan untuk menyalakan notifikasi kamu  ya!",
    "finance.success.home"      : "LIHAT RIWAYAT FINANCING SAYA",
    "finance.success.history"   : "KEMBALI KE MENU KREATOR",
};
  
export default idFinance;