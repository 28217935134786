/* Languange EN */

var enDetailArtist = {
    /* 00.Example */
    "testData": "Homes",
    
    //Profile
    "detailArtist.profile.fans"         : "Fans",
    "detailArtist.profile.rating"       : "Rating",
    "detailArtist.profile.deliv"        : "Delivery time",
    "detailArtist.profile.delivStat"    : "days",
    "detailArtist.profile.price"        : "Price",
    "detailArtist.profile.videoPrice"   : "Price",
    "detailArtist.profile.video"        : "Video Shoutout",
    "detailArtist.profile.videoDisable" : "This service is not available",
    "detailArtist.profile.videoReq"     : "REQUEST",
    "detailArtist.profile.videoReqAct"  : "REQUEST",
    "detailArtist.profile.business"     : "BUSINESS INQUIRY",
    "detailArtist.profile.contact"      : "Contact Us",
    "detailArtist.profile.videoCall"    : "Video Call",
    "detailArtist.profile.videoCallDesc": "Go to App",
    "detailArtist.profile.videoCallReq" : "CHOOSE SCHEDULE",
    "detailArtist.profile.videoCallDur" : "Call Duration",
    "detailArtist.profile.videoCallStd" : "Standby",
    "detailArtist.profile.disable"      : "NOT AVAILABLE",
    "detailArtist.profile.holidayPp"    : " is on vacation right now.",

    //Shootout
    "detailArtist.shootout.title"       : "Best BintanGO Shoutout",
    "detailArtist.shootout.subtitle"    : "Previous Video Shoutout created by ",

    //More Celeb
    "detailArtist.moreCeleb.title"      : "More Celeb to Like",

    //Fans
    "detailArtist.fans.join"            : "Join Fan Club",
    "detailArtist.fans.unjoin"          : "Stop being a Fan",
    "detailArtist.fans.successjoin"     : "Success Join Fan Club",
    "detailArtist.fans.successunjoin"   : "Success Stop Being Fan",

    //Overlay join desc
    "detailArtist.overlay.title"       : "Want to see more?",
    "detailArtist.overlay.desc1"       : "Join ",
    "detailArtist.overlay.desc2"       : " fan club to see more videos?",
    "detailArtist.overlay.join"        : "Join",

    //Tooltip
    "detailArtist.tooltip.vshootout"    : "Video showed are agreed to shown by the user",

    //Menu Dynamic
    "detailArtist.dynamic.vcTitle"      : "Get personalized video shoutout!",
    "detailArtist.dynamic.vcDesc"       : " will record a shoutout video for your special moment",
    "detailArtist.dynamic.order"        : "How to order?",
    "detailArtist.dynamic.vcAppsDesc"   : "Video call will be done on BintanGO Apps",
    "detailArtist.dynamic.mins"         : " mins",

  };
  
export default enDetailArtist;