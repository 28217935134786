/* Languange ID */

var enWithdraw = {
    "withdraw.form.title"       : "Withdraw balance to",
    "withdraw.form.total"       : "Withdrawal Amount",
    "withdraw.form.checkTotal"  : "Withdraw current balance",
    "withdraw.form.checkDesc"   : "You can only do 1 withdrawal per day",
    "withdraw.form.nextBtn"     : "WITHDRAW",
    "withdraw.form.totalConf"   : "Withdrawal amount",
    "withdraw.form.withdrawConf": "Final Withdrawal Amount",
    "withdraw.form.withdrawDesc": "You can only do 1 withdrawal per day",
    "withdraw.form.confirm"     : "CONFIRM",
    "withdraw.form.balance"     : "Current Balance",
    "withdraw.form.addBank"     : "+ ADD BANK ACCOUNT",

    //Addbank
    "withdraw.add.noBankTitle"  : "You don’t have any bank account",
    "withdraw.add.addBankTitle" : "Do you want to add a bank account?",
    "withdraw.add.addBankDesc"  : "To add a bank account, contact our customer service through our official WhatsApp account",
    "withdraw.add.addBankBtn"   : "CONTACT US NOW",

    //Set Bank
    "withdraw.set.setBankBtn"   : "SET AS DEFAULT",

    //Bank List
    "withdraw.list.accTitle"    : "Account Holder Name",

    //Breadcrumb
    "withdraw.bread.withdraw1"  : "Celeb Account", 
    "withdraw.bread.withdraw2"  : "Balance",
    "withdraw.bread.withdraw3"  : "Withdrawal",
    "withdraw.bread.withdraw4"  : "Bank Account",

    // //Error Handling 
    // "withdraw.error.exceedMin"     : "The amount you choose is less than Rp 10,000",
    // "withdraw.error.exceedMax"     : "The Amount that you choose exceeds the limit",
  };
  
export default enWithdraw;