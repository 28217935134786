
import React, { Component, Suspense, lazy } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';

import AppLocale from './lang';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import AppLayout from './layout/AppLayout';
import Footer from "./layout/partial/FooterMVP";
// import SwData from "../public/serviceworker.js";
// import MoEngage from "react-moengage";
// import Footer from "./layout/partial/Footer";

//Public Page
// import SearchPage from './view/public/searchPage';
// import HomeMVP from './view/public/homeMVP';

// import Home from './view/public/home';
// import FAQ from './view/public/faq';
// import Privacy from './view/public/privacy';
// import Condition from './view/public/condition';
// import DownloadApps from './view/public/downloadApps';
// import ErrorPage from './view/public/errorPage';
// import Login from './view/public/loginPage';
// import Detail from './view/public/detail';
// import Register from './view/public/registerPage';
// import ForgotPassword from './view/public/forgotPasswordPage';
// import Verification from './view/public/verificationPage';
// import Order from './view/public/order';
// import SearchCategory from './view/public/searchCategory';
// import ShowListSection from './view/public/showListSection';
// import SingleVideo from './view/public/singleVideo';
// import DashboardFans from './view/dashboard-fans/dashboardFans';
// import DashboardCeleb from './view/dashboard-celeb/dashboardCeleb';
// import MyLinkCeleb from './view/mylink/mylink';
// import CelebProfile from './view/mylink/celebProfile';

const Home = lazy(()=> import("./view/public/home"));
const FAQ = lazy(()=> import("./view/public/faq"));
const Privacy = lazy(()=> import("./view/public/privacy"));
const Condition = lazy(()=> import("./view/public/condition"));
const DownloadApps = lazy(()=> import("./view/public/downloadApps"));
const ErrorPage = lazy(()=> import("./view/public/errorPage"));
const Login = lazy(()=> import("./view/public/loginPage"));
const Detail = lazy(()=> import("./view/public/detail"));
const Register = lazy(()=> import("./view/public/registerPage"));
const ForgotPassword = lazy(()=> import("./view/public/forgotPasswordPage"));
const Verification = lazy(()=> import("./view/public/verificationPage"));
const RedirectSSO = lazy(()=> import("./view/public/redirectSSO"));
const Order = lazy(()=> import("./view/public/order"));
const SearchCategory = lazy(()=> import("./view/public/searchCategory"));
const ShowListSection = lazy(()=> import("./view/public/showListSection"));
const SingleVideo = lazy(()=> import("./view/public/singleVideo"));
const DashboardFans = lazy(()=> import("./view/dashboard-fans/dashboardFans"));
const DashboardCeleb = lazy(()=> import("./view/dashboard-celeb/dashboardCeleb"));
const MyLinkCeleb = lazy(()=> import("./view/mylink/mylink"));
const Invoice = lazy(()=> import("./view/invoice/header"));
const InvoiceDetail = lazy(()=> import("./view/invoice/invoiceDetail"));
const Mission = lazy(()=> import("./view/mission/header"));
const Finance = lazy(()=> import("./view/finance/header"));
const Brand = lazy(()=> import("./view/brand/header"));
const BrandDealsTask = lazy(()=> import("./view/brand-task/header"));
const CelebProfile = lazy(()=> import("./view/mylink/celebProfile"));
const RegisterCeleb = lazy(()=> import("./view/public/registerCelebPage"));
const Analytics = lazy(()=> import("./view/analytics/header"));
// const BrandDetail = lazy(()=> import("./view/brand-deals/brandDetail"));

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
        loading : "d-none"
    }
  }

  async componentDidMount() {
    try {
      this.setState({loading : "d-block"});
      setTimeout(function() {this.setState({loading : "d-none"})}.bind(this), 800) ;
    }
    catch (error) {
      // console.log(error);
    }
    window.scrollTo(0, 0);
  }

  render() {
    const langData = this.props.lang.lang;
    const currentAppLocale = AppLocale[langData];

    var location = window.location.pathname;
    var arLoc = location.split("/");

    // if ('serviceWorker' in navigator) {
    //     window.addEventListener('load', function() {
    //         // navigator.serviceWorker.register('https://localhost/serviceworker.js');
    //         navigator.serviceWorker.register('https://dev.bintango.com/serviceworker.js');
    //     });
    // }

    window.Moengage.call_web_push();
    return (
      <div className="body-of-content">
      
      <div className="h-100">
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <React.Fragment>
            <Router >
              <AppLayout>
              <Suspense 
              fallback={<div id="preloader" className={this.state.loading} />}
              >
                <Switch>
                  <Route exact path='/'>
                    <div className="dashboard-wrapper"><Home /></div>
                    <Footer />
                  </Route>

                  <Route exact path='/home'>
                    <div className="dashboard-wrapper"><Home /></div>
                    <Footer />
                  </Route>

                  {/* <Route exact path='/'>
                  <div className="dashboard-wrapper"><HomeMVP /></div>
                  </Route>
                  <Route exact path='/home'>
                    <div className="dashboard-wrapper"><HomeMVP /></div>
                  </Route> */}

                  <Route exact path='/condition'>
                    <div className="dashboard-wrapper"><Condition /></div>
                    <Footer />
                  </Route>

                  <Route exact path='/privacy'>
                    <div className="dashboard-wrapper"><Privacy /></div>
                    <Footer />
                  </Route>
                  
                  <Route exact path='/getapp'>
                    <DownloadApps />
                  </Route>

                  <Route exact path='/artist/:id' render={(props) => 
                      <div>
                          <Detail {...props} />
                          <Footer />
                      </div> 
                    } />

                  {/* <Route exact path='/celeb/:celebName' render={(props) => 
                      <div>
                          <Detail {...props} />
                          <Footer />
                      </div> 
                    } /> */}

                  <Route exact path='/singleVideo/:id' render={(props) => 
                      <div>
                          <SingleVideo {...props} />
                          <Footer />
                      </div> 
                    } />

                  <Route exact path='/videoProfile/:id/:celebId' render={(props) => 
                      <div>
                          <SingleVideo {...props} />
                          <Footer />
                      </div> 
                    } />

                  {/* <Route path="/search" component={SearchPage} /> */}

                  <Route path="/searchCategory" render={(props) => 
                      <div>
                          <SearchCategory {...props} />
                          <Footer />
                      </div> 
                    } />
                    
                  <Route path="/showListSection" render={(props) => 
                      <div>
                          <ShowListSection {...props} />
                          <Footer />
                      </div> 
                    } />
                    
                    <Route path="/showListVideoCall" render={(props) => 
                      <div>
                          <ShowListSection {...props} />
                          <Footer />
                      </div> 
                    } />

                  <Route path='/nopage' render={(props) => 
                      <div>
                          <ErrorPage {...props} />
                          <Footer />
                      </div> 
                    } />

                  <Route path='/login' render={(props) => 
                      <div>
                          <Login {...props} />
                          <Footer />
                      </div> 
                    } />

                  <Route path='/register' render={(props) => 
                      <div>
                          <Register {...props} />
                          <Footer />
                      </div> 
                    } /> 
                    
                    <Route path='/registerCeleb' render={(props) => 
                      <div>
                          <RegisterCeleb {...props} />
                          <Footer />
                      </div> 
                    } />       

                  <Route path='/verify' render={(props) => 
                      <div>
                          <Verification {...props} />
                          <Footer />
                      </div> 
                    } /> 

                  <Route path='/sso' render={(props) => 
                      <div>
                          <RedirectSSO {...props} />
                      </div> 
                    } /> 

                  <Route path='/forgotPassword' render={(props) => 
                      <div>
                          <ForgotPassword {...props} />
                          <Footer />
                      </div> 
                    } /> 

                  <Route path='/order' render={(props) => 
                      <div>
                          <Order {...props} />
                          <Footer />
                      </div> 
                    } /> 

                  <Route path='/faq' render={(props) => 
                      <div>
                          <FAQ {...props} />
                          <Footer />
                      </div> 
                    } /> 

                  <Route path='/dashboard/fans/' render={(props) => 
                      <div>
                          <DashboardFans {...props} />
                          <Footer />
                      </div> 
                    } />

                  <Route path='/dashboard/celeb/' render={(props) => 
                      <div>
                          <DashboardCeleb {...props} />
                          <Footer />
                      </div> 
                    } />

                  <Route path='/analytics/' render={(props) => 
                    <div>
                        <Analytics {...props} />
                        {/* <Footer /> */}
                    </div> 
                  } />

                  <Route path='/mylink/' render={(props) => 
                    <div>
                        <MyLinkCeleb {...props} />
                        {/* <Footer /> */}
                    </div> 
                  } />

                  <Route path='/invoice/' render={(props) => 
                    <div>
                        <Invoice {...props} />
                        {/* <Footer /> */}
                    </div> 
                  } />

                  <Route path='/invoice-detail/:id' render={(props) => 
                    <div>
                        <InvoiceDetail {...props} />
                        {/* <Footer /> */}
                    </div> 
                  } />

                  <Route path='/mission/' render={(props) => 
                    <div>
                        <Mission {...props} />
                        {/* <Footer /> */}
                    </div> 
                  } />

                  <Route path='/finance/' render={(props) => 
                    <div>
                        <Finance {...props} />
                        {/* <Footer /> */}
                    </div> 
                  } />
                  

                  <Route path='/brand/' render={(props) => 
                    <div>
                        <Brand {...props} />
                        {/* <Footer /> */}
                    </div> 
                  } />

                  <Route path='/brand-task/:id' render={(props) => 
                    <div>
                        <BrandDealsTask {...props} />
                        {/* <Footer /> */}
                    </div> 
                  } />
                  
                  {/* <Route path='/serviceworker.js' render={(props) => 
                    <SwData {...props} />
                  } /> */}

                  <Route exact path='/:celebName' render={(props) => 
                      <div>
                          <CelebProfile {...props} />
                      </div> 
                    } />
                  
                  <Route exact path='/celeb/:celebName' render={(props) => 
                      <div>
                          <Detail {...props} />
                          <Footer />
                      </div> 
                    } />

                  <Redirect to="/" />

                </Switch>
                </Suspense>
              </AppLayout>
            </Router>
          </React.Fragment>
        </IntlProvider>
      </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
      lang: state.lang
  }
}

export default connect(mapStateToProps)(App);
