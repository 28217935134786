/* Languange ID */

var idWithdraw = {
    "withdraw.form.title"       : "Tarik Saldo ke",
    "withdraw.form.total"       : "Jumlah Penarikan Saldo",
    "withdraw.form.checkTotal"  : "Tarik seluruh saldo saat ini",
    "withdraw.form.checkDesc"   : "Kamu hanya bisa melakukan penarikan saldo dalam 1 hari",
    "withdraw.form.nextBtn"     : "TARIK SALDO",
    "withdraw.form.totalConf"   : "Jumlah penarikan saldo",
    "withdraw.form.withdrawConf": "Jumlah Akhir Penarikan Saldo ",
    "withdraw.form.withdrawDesc": "Kamu hanya dapat melakukan 1 penarikan saldo per hari",
    "withdraw.form.confirm"     : "Konfirmasi",
    "withdraw.form.balance"     : "Saldo Saat Ini",
    "withdraw.form.addBank"     : "+ TAMBAH REKENING BANK",
    
    //Addbank
    "withdraw.add.noBankTitle"  : "Kamu belum mempunyai rekening bank",
    "withdraw.add.addBankTitle" : "Kamu mau menambahkan rekening bank?",
    "withdraw.add.addBankDesc"  : "Untuk menambahkan rekening bank, silakan hubungi customer service kami melalui akun Whatsapp Official BintanGO",
    "withdraw.add.addBankBtn"   : "HUBUNGI KAMI",

    //Set Bank
    "withdraw.set.setBankBtn"   : "ATUR SEBAGAI UTAMA",

    //Bank List
    "withdraw.list.accTitle"    : "Nama Pemegang Rekening",

    //Breadcrumb
    "withdraw.bread.withdraw1"  : "Akun Celeb", 
    "withdraw.bread.withdraw2"  : "Saldo",
    "withdraw.bread.withdraw3"  : "Penarikan",
    "withdraw.bread.withdraw4"  : "Rekening Bank",

    // //Error Handling 
    // "withdraw.error.exceedMin"     : "Jumlah yang anda pilih kurang dari Rp 10.000",
    // "withdraw.error.exceedMax"     : "Jumlah yang anda pilih kurang melebihi batas saldo",
  };
  
export default idWithdraw;