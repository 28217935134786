/* Languange EN */

var enUS = {
  /* 00.Example */
  
  
  /* 01.General */
  "general.showAll"         : "Show All",
  "general.category"        : "Category",
  "general.showMore"        : "Show More",
  "general.of"              : "of",
  "general.or"              : "Or",
  "general.tryAgain"        : "TRY AGAIN",
  "general.close"           : "CLOSE",

  //Misc
  "general.topBannerPromo"  : "% 20% discount for first-time shoutout video orders",

  //Video
  "general.video.share"       : "Share",
  "general.video.download"    : "Download",
  "general.video.report"      : "Report",
  "general.video.reasonTitle" : "Reason to report video ",
  "general.video.reasonBtn"   : "Report",

  //Celeb
  "general.celeb.with"        : " with ",
  "IDR"                       : "IDR ",

  //Form Error Validation
  "errMsgReq"                 : "Required.",
  "errMsgEmail"               : "Email is not valid."
};

export default enUS;
