/* Languange ID */

var idDashboard = {
    "dashboard.accountSettings"       : "Pengaturan Akun",
    "dashboardFans.myOrder"           : "Pesanan Saya",
    "dashboardFans.myVideo"           : "Video Saya",
    "dashboardFans.wishlist"          : "Fan Club",
    "dashboardFans.creator"           : "Are you a creator?",
    "dashboardFans.regHere"           : "REGISTER HERE",

    "dashboardCeleb.amountof"         : "Jumlah",
    "dashboardCeleb.myEarnings"       : "Penghasilan Saya",
    "dashboardCeleb.balance"          : "Saldo",
    "dashboardCeleb.balancewithdraw"  : "Saldo & Penarikan",
    "dashboardCeleb.bankaccount"      : "Rekening Bank",
    "dashboardCeleb.earnings"         : "Penghasilan Saya",
    "dashboardCeleb.services"         : "Layanan",
    "dashboardCeleb.home"             : "Beranda",
    "dashboardCeleb.socialInsight"    : "Social Insight",
    "dashboardCeleb.linkinbio"        : "My Link",
    "dashboardCeleb.invoiceHistory"   : "Invoice",
    "dashboardCeleb.finance"          : "Finance",
    "dashboardCeleb.brand"            : "Brand Deals",
    "dashboardCeleb.mission"          : "Creator Mission",
    "dashboardCeleb.comingsoon"       : "SEGERA HADIR!",
    "dashboardCeleb.myTask"           : "Tugas Saya",
    "dashboardCeleb.experience"       : "Pengalaman Fans",
    "dashboardCeleb.holidayMode"      : "Mode Liburan",
    "dashboardCeleb.fans"             : "Fan",
    "dashboardCeleb.ratings"          : "Penilaian",
    "dashboardCeleb.points"           : "Poin",
    "dashboardCeleb.review"           : "Ulasan",
    "dashboardCeleb.workDelivery"     : "Waktu Pengerjaan",
    "dashboardCeleb.lifetimeEarnings" : "Penghasilan",
    "dashboardCeleb.lifetimeEarnings2": "Penghasilan Sejak Bergabung",
    "dashboardCeleb.days"             : "Hari",
    "dashboardCeleb.withdraw"         : "TARIK SALDO",
    "dashboardCeleb.info"             : "Tugas video call kamu bisa diakses melalui aplikasi BintanGO",
    "dashboardCeleb.infoNew"          : "Try Our New Feature My Link!",
    "dashboardCeleb.VA.seeDetail"     : "See Detail",
    "dashboardCeleb.celebritySetting" : "Celebrity Setting",
    "dashboardCeleb.home.verified.title" : "Kamu sudah menjadi Verified Creator",
    "dashboardCeleb.home.verified.desc" : "Mulai perjalanan kamu dengan kami untuk grow beyond. Yuk lihat apa yang dapat kamu lakukan sekarang 😉",
    "dashboardCeleb.home.verified.myLinkTitle" : "Buat Portofolio Kamu",
    "dashboardCeleb.home.verified.myLinkDesc" : "Kreasikan Portofolio dengan gaya kamu dengan BintanGO MyLink",
    "dashboardCeleb.home.verified.invoiceTitle" : "Kirim Invoice kamu",
    "dashboardCeleb.home.verified.invoiceDesc" : "Buat dan kirim dengan mudah Invoice kamu melalui BintanGO",
    "dashboardCeleb.home.verified.financeTitle" : "Ajukan Financing Sekarang",
    "dashboardCeleb.home.verified.financeDesc" : "Capek menunggu pembayaran? Ajukan financing untuk dibayar lebih cepat!",
    "dashboardCeleb.home.verified.missionTitle" : "Selesaikan Misinya",
    "dashboardCeleb.home.verified.missionDesc" : "Selesaikan misi, dapatkan poin nya dan tukarkan rewardnya!",
    
    "dashboardCeleb.home.basic.title" : "Kami senang kamu sudah menjadi bagian dari kami!",
    "dashboardCeleb.home.basic.desc"  : "Terima kasih sudah menjadi Creator BintanGO. Kamu dapat grow beyong lagi dengan fitur creator lain nya dengan menjadi Verified Creator, yuk gabung!",
    
    "accountSettings.editProfile"     : "Ubah Profil",
    "accountSettings.socialMedia"     : "Social Media",
    "accountSettings.changePassword"  : "Ganti Password",

    "accountSettingsSocialMedia.instagram": "INSTAGRAM USERNAME*",
    "accountSettingsSocialMedia.tiktok"   : "TIKTOK USERNAME",
    "accountSettingsSocialMedia.youtube"  : "YOUTUBE CHANNEL",
    "accountSettingsSocialMedia.twitter"  : "TWITTER USERNAME",
    
    "accountSettingsSocialMedia.titleNotValidIG"  : "We cannot find your IG",
    "accountSettingsSocialMedia.descNotValidIG"  : "It looks like your instagram is not a business account, you will miss some of our features. But don't worry, you can still continue to register as a creator",
    "accountSettingsSocialMedia.tryAgain"  : "TRY AGAIN",
    "accountSettingsSocialMedia.continue"  : "CONTINUE",

    "accountSettingsChangePassword.note"  : "Kami akan mengirimkan URL untuk reset password kamu. Pastikan email yang kamu input sudah sesuai dengan yang terdaftar untuk akun ini.",
    "accountSettingsChangePassword.email" : "EMAIL",
    "accountSettingsChangePassword.resetPassword" : "Reset Password",
    "accountSettingsChangePassword.sendLink" : "Kirim Link",

    "accountSettingsEditProfile.changepp" : "GANTI GAMBAR PROFIL",
    "accountSettingsEditProfile.fullname" : "NAMA LENGKAP",
    "accountSettingsEditProfile.username" : "USERNAME",
    "accountSettingsEditProfile.yourProvince" : "PROVINSI",
    "accountSettingsEditProfile.yourCity" : "KOTA",
    "accountSettingsEditProfile.bio"      : "BIO",
    "accountSettingsEditProfile.gender"   : "JENIS KELAMIN",
    "accountSettingsEditProfile.man"      : "Pria",
    "accountSettingsEditProfile.woman"    : "Wanita",
    "accountSettingsEditProfile.birthday" : "TANGGAL LAHIR",
    "accountSettingsEditProfile.reset"    : "ATUR ULANG",
    "accountSettingsEditProfile.save"     : "Simpan Perubahan",
    "accountSettingsEditProfile.savenback": "Save & back",
    "accountSettingsEditProfile.category" : "KATEGORI",
    "accountSettingsEditProfile.mainCategory" : "KATEGORI UTAMA",
    "accountSettingsEditProfile.subCategory" : "SUB KATEGORI (MAKSIMAL 3 KATEGORI)",
    "accountSettingsEditProfile.phone" : "NOMOR HP",
    "accountSettingsEditProfile.note" : "Informasi Personal tidak dapat dilihat oleh pengguna. Apabila ingin melakukan perubahan email atau nomor telepon harap menghubungi team customer service melalui whatsapp ke ",
    
    "accountSettingsEditProfile.selectCategory" : "Pilih Kategori",
    "accountSettingsEditProfile.selectSubcategory" : "Pilih Subkategori",
    "accountSettingsEditProfile.selectAll" : "Select All",
    "accountSettingsEditProfile.clearAll" : "CLEAR ALL",

    "myOrder.allOrder"                : "Semua Pesanan",
    "myOrder.onProcess"               : "Di proses",
    "myOrder.completed"               : "Selesai",
    "myOrder.expired"                 : "Kadaluarsa",
    "myOrder.rejected"                : "Ditolak",
    "myOrder.titleDownloadBintango"   : "Mau lihat orderan kamu?",
    "myOrder.descDownloadBintango"    : "Silahkan download aplikasi BintanGO untuk melihat orderan kamu secara detail",

    "myOrderDetail.orderDetail"       : "Rincian Pesanan",
    "myOrderDetail.orderHistory"      : "Riwayat Pesanan",
    "myOrderDetail.requestBrief"      : "Instruksi request kamu",
    "myOrderDetail.nextPayment"       : "Bayar Sekarang",
    "myOrderDetail.reviewRating"      : "Berikan Ulasan & Rating",

    "myVideo.allVideos"               : "Semua Video",
    "myVideo.publicVideos"            : "Video Publik",
    "myVideo.privateVideos"           : "Video Pribadi",
    "myVideo.titleDownloadBintango"   : "Mau lihat video kamu?",
    "myVideo.descDownloadBintango"    : "Silahkan download aplikasi BintanGO untuk melihat video yang sudah kamu pesan",

    "rating.ratingReview"             : "Beri Rating Ulasan",
    "rating.review"                   : "ULASAN",
    "rating.reviewPlaceholder"        : "Beritahu seleb idola kamu bahwa hadiah yang dia berikan sangat berkesan bagi kamu",
    "rating.thankyou"                 : "Terimasi Kasih",

    "balance.withdrawal"              : "Penarikan Dana",
    "balance.custom"                  : "Custom",
    "balance.today"                   : "Hari Ini",
    "balance.yesterday"               : "Kemarin",
    "balance.thisWeek"                : "Minggu Ini",
    "balance.thisMonth"               : "Bulan Ini",
    "balance.7lastDay"                : "7 Hari Terakhir",
    "balance.30lastDay"               : "30 Hari Terakhir",
    "balance.allTransaction"          : "Semua Transaksi",
    "balance.titleDownloadBintango"   : "Mau lihat saldo kamu?",
    "balance.descDownloadBintango"    : "Silahkan download aplikasi BintanGO untuk melihat saldo kamu secara detail",

    "experience.available"            : "Tersedia",
    "experience.notAvailable"         : "Tidak Tersedia",
    "experience.price"                : "HARGA",
    "experience.workDelivery"         : "WAKTU PENGERJAAN",
    "experience.day"                  : "Hari",
    "experience.week"                 : "Minggu",

    "myTask.allStatus"                : "Semua Status",
    "myTask.inTheProcess"             : "Dalam Proses",
    "myTask.completed"                : "Selesai",
    "myTask.expiredrejected"          : "Expired/Ditolak",
    "myTask.allService"               : "All Service",
    "myTask.vsh"                      : "Video Shoutout",
    "myTask.vca"                      : "Video Call",
    "myTask.allDate"                  : "Semua Tanggal",
    "myTask.90lastDay"                : "90 Hari Terakhir",
    "myTask.30lastDay"                : "30 Hari Terakhir",
    "myTask.customDate"               : "Pilih Tanggal Sendiri",
    "myTask.allTasks"                 : "Semua Tugas",
    "myTask.newTasks"                 : "Tugas Baru",
    "myTask.finishTasks"              : "Tugas Selesai",
    "myTask.ongoingTasks"             : "Tugas Berjalan",
    "myTask.expired"                  : "Kadaluarsa",
    "myTask.rejected"                 : "Ditolak",
    "myTask.titleDownloadBintango"    : "Mau lihat tugas kamu?",
    "myTask.descDownloadBintango"     : "Silahkan download aplikasi BintanGO untuk melihat tugas kamu secara detail",
    
    "myTaskDetail.reject"             : "Tolak",
    "myTaskDetail.accept"             : "Terima Permintaan",
    "myTaskDetail.confirmAccept"      : "Kamu yakin ingin menerima tugas ini?",
    "myTaskDetail.confirmReject"      : "Kamu yakin ingin menolak tugas ini?",
    "myTaskDetail.reason"             : "Pilih Alasan",
    
    "earnings.project"                : "Proyek Bisnis",

    "myLink.design"                   : "DESIGN",
    "myLink.mylink"                   : "MY LINK",

    "noData"                          : "Tidak ada data",
    "to"                              : "untuk",
    "DateTime"                        : "Tanggal & Waktu",
    "dueDate"                         : "Tenggat Waktu",
    "totalAmount"                     : "Jumlah Pembayaran",
    "transferAmount"                  : "Transfer Amount",
    "deductionFee"                    : "Deduction Fee",
    "totalPointEarned"                : "Total Point Earned",
    "Amount"                          : "Jumlah",
    "back"                            : "Kembali",
    "thankyou"                        : "Terima Kasih",
    "DownloadBintangoNow"             : "Download Sekarang",
    "save"                            : "Simpan",
    "cancel"                          : "Batal",
    "yes"                             : "Iya",
    "publicVideo"                     : "Video Publik",
    "privateVideo"                    : "Video Pribadi",
    "schedule"                        : "Jadwal Video Call",
    "Pts"                             : "poin",
    "Points"                          : "poin",
    "earningshistory"                 : "Riwayat Penghasilan",
    "transactionhistory"              : "Riwayat Transaksi",
    "account"                         : "Akun",
    "redeem"                          : "Tukar",
    
  };
  
export default idDashboard;