/* Languange EN */

var enBizzInquiry = {
    "bizzInquiry.form.title"           : "Business Inquiry",
    "bizzInquiry.form.email"           : "WORK EMAIL",
    "bizzInquiry.form.fullName"        : "PIC FULL NAME",
    "bizzInquiry.form.companyName"     : "COMPANY NAME",
    "bizzInquiry.form.phoneNumber"     : "WORK PHONE NUMBER",
    "bizzInquiry.form.position"        : "POSITION",
    "bizzInquiry.form.inqType"         : "INQUIRIES TYPE",
    "bizzInquiry.form.inqTypeDefault"  : "-- Choose One --",
    "bizzInquiry.form.summary"         : "SHORT BRIEF",
    "bizzInquiry.form.submitButton"    : "SUBMIT INQUIRY",
    "bizzInquiry.form.successTitle"    : "Business Inquiry\nSent Successfully",
    "bizzInquiry.form.desc1"           : "BintanGO will contact you soon as we got answer from celeb",
    "bizzInquiry.form.successButton"    : "CLOSE DIALOG",
    "bizzInquiry.err.phone"    : "Input phone number using format 08xxxx",
    "required" : "required",
  };
  
export default enBizzInquiry;