/* Languange EN */

var enRegister = {
    //Register
    "register.form.title"           : "Sign Up",
    "register.form.email"           : "EMAIL",
    "register.form.password"        : "PASSWORD",
    "register.form.confirmpassword" : "CONFIRM PASSWORD",
    "register.form.button"          : "Sign Up",
    "register.form.otherLoginText"  : "Or Sign Up with",
    "register.form.signinText"      : "Already have an account?",
    "register.form.signinLink"      : "Sign In Now",
    "register.form.otherDesc1"      : "By logging in or registering, you agree to our",
    "register.form.otherDesc2"      : "and",
    "register.form.otherTc"         : "Terms of Service",
    "register.form.otherPolicy"     : "Privacy Policy",
    "register.form.regCreator"      : "Want to register as creator?",
    "register.form.regCreatorLink"  : "Sign up Now",
  };
  
export default enRegister;