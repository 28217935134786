/* Languange EN */

var enBioDesign = {
    
    // Header
    "bioDesign.header.title"            : "Header",
    "bioDesign.header.subtitle"         : "Show your best profile  ",
    "bioDesign.header.ppTitle"          : "CHANGE PROFILE PICTURE",
    "bioDesign.header.shapeTitle"       : "Profile Picture Shape",
    "bioDesign.header.shapeBorder"      : "Add outline outside my profile picture",
    "bioDesign.header.shapeBorderColor" : "Outline color",
    "bioDesign.header.textColor"        : "Text color",
    "bioDesign.header.textTitle"        : "Header Text",
    "bioDesign.header.textSubTitle"     : "DISPLAY NAME (LEAVE BLANK TO RESET)",
    "bioDesign.header.textBioTitle"     : "BIO",
    "bioDesign.header.textLocTitle"     : "LOCATION",
    "bioDesign.header.inputChar"        : "characters",

    //Template
    "bioDesign.template.title"            : "Themes",
    "bioDesign.template.subtitle"         : "We give you shortcut with instant theme options",
    "bioDesign.template.blank"            : "Blank",
    "bioDesign.template.light"            : "Light",
    "bioDesign.template.dark"             : "Dark",
    
    //Block Style
    "bioDesign.block.title"            : "Block Style",
    "bioDesign.block.subtitle"         : "Show your personality on the block",
    "bioDesign.block.sqbox"            : "Square box",
    "bioDesign.block.sftcnr"           : "Soft corner",
    "bioDesign.block.alround"          : "All a-round",
    "bioDesign.block.fwidth"           : "Full width",
    "bioDesign.block.outline"          : "Add outline",
    "bioDesign.block.shadow"           : "Add shadow",
    "bioDesign.block.clrBlock"         : "Blocks",
    "bioDesign.block.clrBlockTxt"      : "Block text",
    "bioDesign.block.clrBlockLink"     : "Block link",
    "bioDesign.block.clrIcon"          : "Icon",
    "bioDesign.block.clrOutline"       : "Outline",
    "bioDesign.block.clrShadow"        : "Shadow",  

    //Background
    "bioDesign.bg.title"                : "Background",
    "bioDesign.bg.subtitle"             : "Choose your impressive background",
    "bioDesign.bg.color"                : "Color",
    "bioDesign.bg.colorDesc"            : "Background Color",
    "bioDesign.bg.image"                : "Image",
    "bioDesign.bg.imageSub1"            : "Photos by ",
    "bioDesign.bg.imageSub2"            : ". Search the same term again for different results.",
    "bioDesign.bg.imagePlaceHolder"     : "Search images",
    "bioDesign.bg.imageUrlTitle"        : "Add Custom Background",
    "bioDesign.bg.imageSubmit"          : "SUBMIT",
    "bioDesign.bg.imageUseBg"           : "USE MY OWN BACKGROUND",
    "bioDesign.bg.imageChgUrlBg"        : "CHANGE MY OWN BACKGROUND",
    "bioDesign.bg.video"                : "Video",
    "bioDesign.bg.videoPlaceHolder"     : "Search videos",
    "bioDesign.bg.validation"           : "please input correct image URL",

    //Font
    "bioDesign.font.title"            : "Fonts",
    "bioDesign.font.subtitle"         : "Adjust your fonts that fits your theme",
    "bioDesign.font.choose"           : "CHOOSE FONT",

    //Social Media
    "bioDesign.sosmed.title"            : "Social Icons",
    "bioDesign.sosmed.subtitle"         : "Add your social media for better engagement",
    "bioDesign.sosmed.addBtnList"       : "+ ADD NEW",
    "bioDesign.sosmed.addTitle"         : "Add New Social Icon",
    "bioDesign.sosmed.platformTitle"    : "PLATFORM",
    "bioDesign.sosmed.urlTitle"         : "URL LINK",
    "bioDesign.sosmed.addBtn"           : "ADD SOCIAL MEDIA",
    "bioDesign.sosmed.updateBtn"        : "UPDATE",
    "bioDesign.sosmed.deleteBtn"        : "DELETE",
    "bioDesign.sosmed.cancelBtn"        : "CANCEL",
    "bioDesign.sosmed.validateLink"     : "please input correct URL format",
    "bioDesign.sosmed.popUpDelTitle"    : "We just want to confirm",
    "bioDesign.sosmed.popUpDelSubTitle" : "Are you sure want to delete this icon?",

    //Live Preview
    "bioDesign.footer.viewVer2m1"       : "Create something like this for free",
    "bioDesign.footer.viewVer2m2"       : "as BintanGO creator! Register now",

  };
  
export default enBioDesign;