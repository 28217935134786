/* Languange ID */

var idRegister = {
    //Register
    "register.form.title"           : "Daftar",
    "register.form.email"           : "EMAIL",
    "register.form.password"        : "PASSWORD",
    "register.form.confirmpassword" : "KONFIRMASI PASSWORD",
    "register.form.button"          : "Daftar",
    "register.form.otherLoginText"  : "Atau daftar dengan",
    "register.form.signinText"      : "Sudah punya akun?",
    "register.form.signinLink"      : "Masuk sekarang",
    "register.form.otherDesc1"      : "Dengan masuk atau mendaftar, kamu telah menyetujui",
    "register.form.otherDesc2"      : "dan",
    "register.form.otherTc"         : "Ketentuan Layanan",
    "register.form.otherPolicy"     : "Kebijakan Privasi",
    "register.form.regCreator"      : "Ingin daftar sebagai creator?",
    "register.form.regCreatorLink"  : "Daftar sekarang",
  };
  
export default idRegister;