/* Languange ID */

var idOrder = {
    "orderRequest.request"            : "Request untuk",
    "orderRequest.requestVC"          : "Request Video Call untuk",
    "orderRequest.with"               : "dengan",
    "orderRequest.vcSession"          : "Video Call Session dengan",
    "orderRequest.videoto"            : "Untuk siapa video ini?",
    "orderRequest.other"              : "Orang lain",
    "orderRequest.myself"             : "Saya sendiri",
    "orderRequest.yourName"           : "Nama Kamu *",
    "orderRequest.recipientName"      : "Nama Teman *",
    "orderRequest.senderName"         : "Nama Pengirim *",
    "orderRequest.occasion"           : "Pilih Acara *",
    "orderRequest.chooseOne"          : "--Pilih salah satu--",
    "orderRequest.descVideo"          : "Instruksi request kamu *",
    "orderRequest.phdescVideo"        : "Tulis sedetail mungkin request kamu untuk seleb idola kamu. Contoh: Katya ulang tahun di tgl 16 nanti, minta tolong kasih dia ucapan yang terbaik ya kak. Jangan lupa juga untuk ngomong 'sarangheyo'",
    "orderRequest.character"          : "karakter",
    "orderRequest.mincharacter"       : "Harap ketik minimal 100 karakter",
    "orderRequest.submit"             : "Kirim Permintaan",
    "orderRequest.next"               : "LANJUTKAN",
    "orderRequest.sessionvc"          : "Pilih jadwal yang tersedia",
    "orderRequest.standby"            : "Persiapan",
    "orderRequest.standbyFrom"        : "Bersiap dari *",
    "orderRequest.mins"               : "menit",
    "orderRequest.sold"               : "Terjual",
    "orderRequest.callDuration"       : "Durasi Panggilan",
    "orderRequest.price"              : "Harga",
    "orderRequest.infoTime"           : "*) Jadwal ditampilkan berdasarkan waktu setempat",
    "orderRequest.agreed"             : "Saya telah menyetujui",
    "orderRequest.bintangoTC"         : "Syarat dan ketentuan dari BintanGO",

    "orderConfirmation.edit"          : "Ubah",
    "orderConfirmation.to"            : "untuk",
    "orderConfirmation.forme"         : "Saya",
    "orderConfirmation.hideMyVideo"   : "Sembunyikan video di profil saya dan celeb",
    "orderConfirmation.infosend"      : "Info Pengiriman",
    "orderConfirmation.standard"      : "Standard",
    "orderConfirmation.day"           : "Hari",
    "orderConfirmation.hourDelivery"  : "Hour delivery",
    "orderConfirmation.getInOneDay"   : "Get it delivery in one day!",
    "orderConfirmation.summary"       : "Ringkasan Pembayaran",
    "orderConfirmation.promoCode"     : "Promo code",
    "orderConfirmation.shoutout"      : "BintanGO video shoutout",
    "orderConfirmation.videocall"     : "BintanGO video call",
    "orderConfirmation.discount"      : "Diskon",
    "orderConfirmation.videoShoutoutFee" : "Biaya Video Shoutout",
    "orderConfirmation.promotionContribution" : "Kontribusi Promosi",
    "orderConfirmation.nettIncome"    : "Pendapatan Akhir",
    "orderConfirmation.fee"           : "Biaya Platform",
    "orderConfirmation.vat"           : "PPN 10%",
    "orderConfirmation.total"         : "Total Pembayaran",
    "orderConfirmation.nextPayment"   : "Pilih Metode Pembayaran",
    "orderConfirmation.inputVoucher"  : "Masukkan kode voucher disini",
    "orderConfirmation.remove"        : "Hapus",
    "orderConfirmation.valid"         : "Voucher berhasil digunakan",
    "orderConfirmationVC.nextPayment" : "LANJUTKAN PEMBAYARAN",
    "orderConfirmationVC.schedule"    : "Jadwal Video Call",
    "orderConfirmationVC.followTime"  : "Mengikuti waktu setempat",
    "orderConfirmationVC.guidelines"  : "Petunjuk Video Call",
    "orderConfirmationVC.guidelines0"  : "Kamu harus mendownload aplikasi BintanGO untuk melakukan video call",
    "orderConfirmationVC.guidelines1"  : "Add Tambahkan jadwal panggilan video di kalender setelah idolamu menerima permintaan",
    "orderConfirmationVC.guidelines2"  : "Pastikan koneksi internet stabil",
    "orderConfirmationVC.guidelines3"  : "Masuk ke ruang panggilan video melalui rincian pesanan",
    "orderConfirmationVC.guidelines4"  : "Kamu bisa bersiap selama 5 menit di ruang tunggu sebelum terhubung dengan idolamu",

    "orderPayment.infoafterPayment"   : "Silahkan cek transaksi kamu secara berkala untuk update status transaksi kamu",
    "orderPayment.backHome"           : "Kembali ke Beranda",
    "orderPayment.mytransaction"      : "Lihat Transaksi Saya",

    "orderVideoCall.waiting"          : "Video call akan dilakukan di aplikasi BintanGO",
    "orderVideoCall.appsLink"         : "Download Aplikasi Bintango",
    "orderVideoCall.apps"             : "untuk melakukan video call",
  };
  
export default idOrder;