import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

class FooterTalent extends Component {
  render() {
    return (
        <div className="static-page-request d-flex justify-content-between align-items-center">
            <div className="col-4 col-img-static"><img src="/assets/img/mockup-phone.png" /></div>
            <div className="col-4 col-img-info">
                <h1><FormattedMessage id="footer.banner.title" /></h1>
                <p><FormattedMessage id="footer.banner.desc" /></p>
                {/* <div className="button-request mt-2"><FormattedMessage id="footer.banner.button" /></div> */}
            </div>
            <div className="col-4 col-img-static"><img src="/assets/img/celebrity-ten2five.png" /></div>
        </div>
    );
  }
}

export default FooterTalent;