/* Languange EN */

var enBioManage = {
    
    "manageLink.addBlock"        : "+ ADD NEW BLOCK",
    "manageLink.chooseBlock"     : "Choose Block Type",
    "manageLink.menuLink"        : "Link",
    "manageLink.menuText"        : "Text",
    "manageLink.menuGallery"     : "Gallery",
    "manageLink.menuSocial"      : "Social Media Post",

    "manageLink.title"           : "TITLE",
    "manageLink.urlLink"         : "URL LINK",
    "manageLink.emojiIcon"       : "EMOJI ICON",
    "manageLink.position"        : "POSITION",
    "manageLink.addLink"         : "ADD LINK",
    "manageLink.updateLink"      : "UPDATE LINK",
    "manageLink.left"            : "Left",
    "manageLink.right"           : "Right",
    "manageLink.both"            : "Left & Right",
    "manageLink.linkBlock"       : "Link Block",

    "manageLink.text"            : "TEXT",
    "manageLink.addText"         : "ADD TEXT",
    "manageLink.updateText"      : "UPDATE TEXT",
    "manageLink.textBlock"       : "Text Block",

    "manageLink.gallery"         : "GALLERY TITLE",
    "manageLink.titleasHeader"   : "Title as header",
    "manageLink.titleasCaption"  : "Title as caption",
    "manageLink.gallerymin3"     : "GALLERY (CHOOSE MIN 3 IMAGES)",
    "manageLink.addNewGrid"      : "ADD NEW GRID",
    "manageLink.addGallery"      : "ADD GALLERY",
    "manageLink.updateGallery"   : "UPDATE GALLERY",
    "manageLink.addImage"        : "Add Image",
    "manageLink.submit"          : "SUBMIT",
    "manageLink.galleryBlock"    : "Gallery Block",

    "manageLink.addSocial"       : "ADD SOCIAL LINK",
    "manageLink.updateSocial"    : "UPDATE SOCIAL LINK",
    "manageLink.socialBlock"     : "Social Media Post Block",

    "manageLink.confirmDelete"   : "We just want to confirm..",
    "manageLink.confirmDeleteBlock"  : "Are you sure want to delete this block?",
    "manageLink.confirmDeleteGrid"  : "Are you sure want to delete this grid?",
    "manageLink.yes"             : "YES",
    "manageLink.cancel"          : "CANCEL",
  };
  
export default enBioManage;