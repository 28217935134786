/* Languange EN */

var enInvoiceStep = {
    //General
    "invoiceStep.general.draft"     : "SAVE AND CLOSE",
    "invoiceStep.general.share"     : "SHARE INVOICE",
    "invoiceStep.general.next"      : "NEXT",
    "invoiceStep.general.toHome"    : "BACK TO HOME",
    "invoiceStep.general.send"      : "SEND",

    //Step 1
    "invoiceStep.step1.desc"  : "Please choose a design for your invoice",

    //Step 2
    "invoiceStep.step2.title"       : "Invoice Information",
    "invoiceStep.step2.number"      : "Invoice Number *",
    "invoiceStep.step2.date"        : "Invoice Date *",
    "invoiceStep.step2.terms"       : "Terms *",
    "invoiceStep.step2.termsInfo"   : "How long you expect the client to make payment. maximum terms is 30 days.",
    "invoiceStep.step2.dueDate"     : "Due Date *",

    //Step 3
    "invoiceStep.step3.title"       : "Client Information",
    "invoiceStep.step3.name"        : "Client Name *",
    "invoiceStep.step3.namePh"      : "Company or brand name",
    "invoiceStep.step3.address"     : "Client Address",
    "invoiceStep.step3.addressPh"   : "Company address",
    "invoiceStep.step3.pic"         : "Client PIC*",
    "invoiceStep.step3.picPh"       : "John Doe",
    "invoiceStep.step3.email"       : "Working Email *",
    "invoiceStep.step3.emailPh"     : "example@email.com",
    "invoiceStep.step3.wa"          : "Whatsapp",
    "invoiceStep.step3.waPh"        : "081234567890",
    "invoiceStep.step3.saveDraft"   : "Save this client information",

    //Step 4
    "invoiceStep.step4.title"       : "Job Description",
    "invoiceStep.step4.scope"       : "Scope of work",
    "invoiceStep.step4.qty"         : "Qty",
    "invoiceStep.step4.price"       : "Price ",
    "invoiceStep.step4.deadline"    : "Job Deadline",
    "invoiceStep.step4.addMore"     : "ADD MORE WORK",
    "invoiceStep.step4.tax"         : "Tax Inclusive",
    "invoiceStep.step4.sTotal"      : "Sub total",
    "invoiceStep.step4.taxVat"      : "Tax (VAT 10%)",
    "invoiceStep.step4.gTotal"      : "Grand total",
    "invoiceStep.step4.delete"      : "Delete Work",
    "invoiceStep.step4.notes"       : "Notes",
    "invoiceStep.step4.notesPh"     : "You can give a note to your client about the payment or anything, or you can say something nice.",

    //Step 5
    "invoiceStep.step5.title"       : "Summary",

    //Sent Invoice
    "invoiceStep.sent.title"        : "Your Invoice is ready to go!",
    "invoiceStep.sent.desc"         : "Please make sure that this is the correct email. You can add more if you want!",
    "invoiceStep.sent.to"           : "To (Client Email)",
    "invoiceStep.sent.cc"           : "Additional Recipient",

    //Email Invoice
    "invoiceStep.email.title"       : "Your invoice has been sent!",
    "invoiceStep.email.desc"        : "Sit back and relax. Client will get notification for your invoice and we will update the payment status to make sure you get paid 💸",
    "invoiceStep.email.earlyPay"    : "APPLY FOR EARLY PAY"
};
  
export default enInvoiceStep;