/* Languange ID */

var idInvoice = {
    "invoice"                   : "Invoice",
    "invoice.history"           : "Riwayat Invoice",
    "invoice.historyDesc"       : "Lihat dan bagikan Invoice yang kamu buat",
    "invoice.preview"           : "Preview",
    "invoice.create"            : "Buat Invoice",
    "invoice.sent"              : "Invoice Dikirim",
    "invoice.awaitingPayment"   : "Menunggu Pembayaran",
    "invoice.paid"              : "Invoice Dibayar",
    "invoice.createNew"         : "BUAT INVOICE BARU",
    "invoice.edit"              : "Ubah",
    "invoice.download"          : "Unduh",
    "invoice.share"             : "Bagikan",
    "invoice.createdDate"       : "Waktu Dibuat",
    "invoice.dueDate"           : "Jatuh Tempo",
    "invoice.applyForEarlyPay"  : "APPLY FOR EARLY PAY",
    "invoice.earlyPayApproved"  : "EARLY PAY APPROVED",

    "invoice.sort"              : "Urutkan",
    "invoice.sort.dateCreated"  : "Waktu Dibuat",
    "invoice.sort.dueDate"      : "Jatuh Tempo",
    "invoice.sort.clientName"   : "Nama Klien",

    "invoice.filter"            : "Filter",
    "invoice.filter.draft"      : "Draft",
    "invoice.filter.awaitingPayment" : "Menunggu Pembayaran",
    "invoice.filter.paid"       : "Dibayar",
    "invoice.filter.completed"  : "Selesai",
    "invoice.filter.notPaid"    : "Belum Dibayar",
    "invoice.filter.expired"    : "Kadaluarsa",

    "invoice.step1"             : "Step 1",
    "invoice.step2"             : "Step 2",
    "invoice.step3"             : "Step 3",
    "invoice.step4"             : "Step 4",
    "invoice.step5"             : "Step 5",
    "invoice.chooseTemplate"    : "Pilih Template",
    "invoice.info"              : "Informasi Invoice",
    "invoice.clientInfo"        : "Informasi Klien",
    "invoice.jobDescription"    : "Deskripsi Pekerjaan",
    "invoice.summary"           : "Konfirmasi",
    
};
  
export default idInvoice;