/* Languange ID */

var idPopUpPurchaseStep = {
    //Video Shootout
    "popuPurchaseStep.vs.title"      : "Bagaimana cara memesan ?",
    "popuPurchaseStep.vs.step1"      : "Pilih untuk siapa video ini diperuntukkan, apakah untuk dirimu sendiri, atau orang lain.",
    "popuPurchaseStep.vs.step2"      : "Pilih acara yang tepat untuk video shoutout yang kamu request.",
    "popuPurchaseStep.vs.step3"      : "Tulis instruksi kepada idola kamu tentang apa yang harus mereka sampaikan di video shoutout tersebut.",
    "popuPurchaseStep.vs.step4"      : "Pastikan info pesanan dan pembayaran sudah sesuai dengan apa yang kamu tulis sebelumnya.",
    "popuPurchaseStep.vs.step5"      : "Bayar dengan metode pembayaran yang kamu inginkan.",
    "popuPurchaseStep.vs.step6"      : "Setelah pembayaran berhasil, sistem akan memberikan respon bahwa status pembayaran sudah diterima.",
    "popuPurchaseStep.vs.step7"      : "Setelah request kamu selesai, kami akan mengirim notifikasi agar kamu bisa melihat, membagikan, atau mengunduh videonya.",

    //Video Call
    "popuPurchaseStep.vc.title"      : "Bagaimana cara memesan ?",
    "popuPurchaseStep.vc.step1"      : "Jawab pertanyaan dari idolamu agar video call bisa semakin seru",
    "popuPurchaseStep.vc.step2"      : "Pilih jadwal yang tersedia",
    "popuPurchaseStep.vc.step3"      : "Lakukan pembayaran",
    "popuPurchaseStep.vc.step4"      : "Terima konfirmasi permintaan panggilan video dari idolamu",
    "popuPurchaseStep.vc.step5"      : "Kamu harus download Aplikasi BintanGO untuk melakukan video call",
    "popuPurchaseStep.vc.step6"      : "Tambahkan jadwal panggilan video ke kalender",
    "popuPurchaseStep.vc.step7"      : "Masuk ke sesi panggilan video sesuai jadwal",
    "popuPurchaseStep.vc.step8"      : "Tunggu idolamu bersiap di ruang tunggu video call, maksimal 5 menit",
    "popuPurchaseStep.vc.step9"      : "Langsung ngobrol dengan idolamu sesuai durasi",
  };
  
export default idPopUpPurchaseStep;