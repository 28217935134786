/* Languange ID */

var idFooter = {
    //Footer Banner
    "footer.banner.title"           : "Mau lebih dekat dengan idolamu?",
    "footer.banner.desc"            : "Jelajahi dan temukan idolamu, dan berikan pengalaman yang paling berkesan untukmu dan orang-orang sekitar kamu",
    "footer.banner.button"          : "Request idola kamu",

    //Footer Menu Banner
    "footer.menuBanner.grtTitle"     : "Garansi 100% BintanGO",
    "footer.menuBanner.grtDesc"      : "Dapatkan uang Anda kembali jika pesanan Anda tidak diproses oleh selebriti kami. Kami akan memastikan transaksi Anda aman.",

    //Footer Menu Link
    "footer.menuBanner.account"     : "Akun",
    "footer.menuBanner.myProfile"   : "Profil Saya",
    "footer.menuBanner.myOrder"     : "Pesanan Saya",
    "footer.menuBanner.myVideo"     : "Video Saya",
    "footer.menuBanner.whistlist"   : "Fan Club",
    "footer.menuBanner.promo"       : "Promo",
    "footer.menuBanner.myVoucher"   : "Voucher Saya",
    "footer.menuBanner.withdraw"    : "Tarik Saldo",

    //Menu NavBar
    "footer.menuBanner.myTask"              : "Tugas Saya",
    "footer.menuBanner.balance"             : "Saldo",
    "footer.menuBanner.experienceSettings"  : "Pengalaman Fans",

    "footer.menuBanner.sitemap"     : "Sitemap",
    "footer.menuBanner.aboutUs"     : "Tentang Kami",
    "footer.menuBanner.career"      : "Karir",
    "footer.menuBanner.faq"         : "FAQ",
    "footer.menuBanner.blog"        : "Blog",
    "footer.menuBanner.help"        : "Pusat Bantuan",
    "footer.menuBanner.privacy"     : "Kebijakan Privasi",
    "footer.menuBanner.term"        : "Syarat & Ketentuan",

    //Footer Menu NewsLetter
    "footer.menuNews.title"     : "Langganan newsletter kami",
    "footer.menuNews.desc"      : "Jadilah yang pertama mengetahui update dari seleb",
    "footer.menuNews.email"     : "Alamat email",

  };
  
export default idFooter;