/* Languange ID */

var idInvoiceStep = {
    //General
    "invoiceStep.general.draft"     : "TUTUP & SIMPAN",
    "invoiceStep.general.share"     : "BAGIKAN INVOICE",
    "invoiceStep.general.next"      : "NEXT",
    "invoiceStep.general.toHome"    : "KEMBALI",
    "invoiceStep.general.send"      : "KIRIM",

    //Step 1
    "invoiceStep.step1.desc"  : "Pilih bentuk template yang ingin digunakan di invoice kamu",

    //Step 2
    "invoiceStep.step2.title"       : "Informasi Invoice",
    "invoiceStep.step2.number"      : "Nomor Invoice *",
    "invoiceStep.step2.date"        : "Tanggal Invoice Terbit *",
    "invoiceStep.step2.terms"       : "Jangka Waktu *",
    "invoiceStep.step2.termsInfo"   : "Perkiraan jangka waktu yang dibutuhkan klien untuk melakukan pembayaran. Maks 30 hari.",
    "invoiceStep.step2.dueDate"     : "Jatuh Tempo *",

    //Step 3
    "invoiceStep.step3.title"       : "Informasi Klien",
    "invoiceStep.step3.name"        : "Nama Klien *",
    "invoiceStep.step3.namePh"      : "Company or brand name",
    "invoiceStep.step3.address"     : "Alamat Klien *",
    "invoiceStep.step3.addressPh"   : "Company address",
    "invoiceStep.step3.pic"         : "PIC Klien *",
    "invoiceStep.step3.picPh"       : "John Doe",
    "invoiceStep.step3.email"       : "Email Klien *",
    "invoiceStep.step3.emailPh"     : "example@email.com",
    "invoiceStep.step3.wa"          : "Whatsapp",
    "invoiceStep.step3.waPh"        : "081234567890",
    "invoiceStep.step3.saveDraft"   : "Simpan Informasi Klien",

    //Step 4
    "invoiceStep.step4.title"       : "Deskripsi Pekerjaan",
    "invoiceStep.step4.scope"       : "Hal yang Dikerjakan",
    "invoiceStep.step4.qty"         : "Jumlah",
    "invoiceStep.step4.price"       : "Harga per Unit  ",
    "invoiceStep.step4.deadline"    : "Jatuh Tempo",
    "invoiceStep.step4.addMore"     : "TAMBAH PEKERJAAN",
    "invoiceStep.step4.tax"         : "Termasuk Pajak",
    "invoiceStep.step4.sTotal"      : "Sub total",
    "invoiceStep.step4.taxVat"      : "Pajak (PPN 10%)",
    "invoiceStep.step4.gTotal"      : "Grand total",
    "invoiceStep.step4.delete"      : "Hapus Pekerjaan",
    "invoiceStep.step4.notes"       : "Notes",
    "invoiceStep.step4.notesPh"     : "You can give a note to your client about the payment or anything, or you can say something nice.",

    //Step 5
    "invoiceStep.step5.title"       : "Konfirmasi Invoice ",

    //Sent Invoice
    "invoiceStep.sent.title"        : "Invoice kamu siap dikirim!",
    "invoiceStep.sent.desc"         : "Pastikan email dibawah ini sudah benar. Kamu dapat menambahkan email penerima lainnya!",
    "invoiceStep.sent.to"           : "Penerima",
    "invoiceStep.sent.cc"           : "Kirim email juga ke",

    //Email Invoice
    "invoiceStep.email.title"       : "Invoice kamu telah terkirim!",
    "invoiceStep.email.desc"        : "Sit back and relax. Klien akan mendapatkan notifikasi dan kami akan menginformasikan status pembayaran untuk memastikan kamu dibayar 💸",
    "invoiceStep.email.earlyPay"    : "APPLY FOR EARLY PAY"
};
  
export default idInvoiceStep;