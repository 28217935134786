/* Languange ID */

var idBizzInquiry = {
    "bizzInquiry.form.title"           : "Business Inquiry",
    "bizzInquiry.form.email"           : "WORK EMAIL",
    "bizzInquiry.form.fullName"        : "PIC FULL NAME",
    "bizzInquiry.form.companyName"     : "COMPANY NAME",
    "bizzInquiry.form.phoneNumber"     : "WORK PHONE NUMBER",
    "bizzInquiry.form.position"        : "POSITION",
    "bizzInquiry.form.inqType"         : "INQUIRIES TYPE",
    "bizzInquiry.form.inqTypeDefault"  : "-- Pilih Salah Satu --",
    "bizzInquiry.form.summary"         : "SHORT BRIEF",
    "bizzInquiry.form.submitButton"    : "SUBMIT INQUIRY",
    "bizzInquiry.form.successTitle"    : "Business Inquiry\nBerhasil Terkirim",
    "bizzInquiry.form.desc1"           : "BintanGO akan segera menghubungi anda setelah\nmendapat jawaban dari seleb yang bersangkutan",
    "bizzInquiry.form.successButton"    : "TUTUP DIALOG",
    "bizzInquiry.err.phone"    : "Masukan format nomor handphone 08xxxx",
    "required" : "wajib",
  };
  
export default idBizzInquiry;