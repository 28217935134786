/* Languange ID */

var idPopUpVoucher = {
    //General
    "popuVoucher.choose"        : "Pilih",
    "popuVoucher.list.title"    : "Pilih Voucher",
    "popuVoucher.list.input"    : "Cari voucher",
    "popuVoucher.list.apply"    : "TERAPKAN",
    "popuVoucher.detail.title"  : "Rincian Voucher",
    "popuVoucher.detail.copy"   : "Salin",
    "popuVoucher.detail.period" : "Periode Promosi",
    "popuVoucher.detail.tc"     : "Syarat dan Ketentuan",

    //Messages
    "popuVoucher.toast.copy"   : "Disalin ke clipboard",
  };
  
export default idPopUpVoucher;