/* Languange ID */

var idAnalytics = {
    "analytics"                 : "Analytics",
    "analytics.lastUpdate"      : "Last Updated at",
    "analytics.followers"       : "Followers",
    "analytics.subscriber"      : "Subscriber",
    "analytics.followings"      : "Followings",
    "analytics.videoViews"      : "Video Views",
    "analytics.country"         : "Country",
    "analytics.engagement"      : "Engagement",
    "analytics.avgLikes"        : "Avg. Likes",
    "analytics.likes"           : "Likes",
    "analytics.favorites"       : "Favorites",
    "analytics.tweets"          : "Tweets",
    "analytics.avgComment"      : "Avg. Comment",
    "analytics.mediaUploads"    : "Media Uploads",
    "analytics.titleImprove"    : "Tingkatkan permainanmu",
    "analytics.titleHistory"    : "Analytics History",
    "analytics.descHistory"     : "We display your social media data here so you can see your performance at a glance.",
    "analytics.descFollowersHistory"    : "Followers for the last 30 days",
    "analytics.descSubscriberHistory"    : "Subscriber for the last 30 days",
    "analytics.descFollowingsHistory"    : "Followings for the last 30 days",
    "analytics.descLikesHistory": "Likes for the last 30 days",
    "analytics.descVideoViewsHistory": "Video Views for last 30 days",
    "analytics.descMediaHistory": "Media for the last 30 days",
    "analytics.descUploadHistory": "Upload for the last 30 days",
    "analytics.descTweetHistory": "Tweet for the last 30 days",

    "analytics.titleNoAnalytics": "Dapatkan laporan performa akunmu di satu platform",
    "analytics.descNoAnalytics" : "Optimalisasikan kontenmu dan #GrowBeyond dengan insight instan dari akun media sosialmu",
    "analytics.titleUnderReview": "Akun {sosmed} Anda Sedang Dalam Peninjau-an",
    "analytics.descUnderReview" : "Ini mungkin memakan beberapa waktu dan kami akan memberi tahu kamu jika sudah selesai",
    "analytics.connect"         : "CONNECT",
    "analytics.connectNow"      : "CONNECT NOW",
    "analytics.connectAgain"    : "CONNECT AGAIN",
    "analytics.editSocialMedia" : "EDIT SOCIAL MEDIA",
    "analytics.edit"            : "EDIT",
    "analytics.alreadyOptimize" : "Already optimize your account?",

    "analytics.connectMyInstagram" : "CONNECT MY INSTAGRAM",
    "analytics.connectMyYoutube" : "CONNECT MY YOUTUBE",
    "analytics.connectMyTiktok" : "CONNECT MY TIKTOK",
    "analytics.connectMyTwitter" : "CONNECT MY TWITTER",

    "analytics.instagramAccount": "Instagram Account",
    "analytics.YoutubeAccount"  : "Youtube Account",
    "analytics.TiktokAccount"   : "Tiktok Account",
    "analytics.TwitterAccount"  : "Twitter Account",

    "analytics.oppss"           : "Opps Sorry",
    "analytics.cantConnect"     : "We still can't connect with your social media",
    "analytics.contactUs"       : "CONTACT US",
    "analytics.changeUsername"  : "CHANGE USERNAME",

    "analytics.titleInfoOne"    : "Semua akunmu di satu tempat",
    "analytics.descInfoOne"     : "Integrasikan akun media sosialmu dalam hitungan menit. Amati performa dan perkembanganmu dalam satu tempat.",
    "analytics.titleInfoTwo"    : "Dapatkan insight",
    "analytics.descInfoTwo"     : "#GrowBeyond sebagai creator dengan insight khusus untukmu.",
    "analytics.titleInfoThree"  : "Perkuat akunmu",
    "analytics.descInfoThree"   : "Ga cuma insights! Kita punya tips untuk kamu berdasarkan insight analytics.",

    "analytics.modalTitleInstagram"  : "Let's optimize your Instagram account",
    "analytics.modalDescInstagram"  : "To get the complete statistics of your Instagram:",
    "analytics.pointOneInstagram"  : "Set to business or creator account",
    "analytics.pointTwoInstagram"  : "Connected to your facebook page",
    "analytics.pointThreeInstagram"  : "Has  at least 100 followers",
    "analytics.modalTitleYoutube"  : "Let's optimize your YouTube account",
    "analytics.modalDescYoutube"  : "To be able to connect your Youtube Account :",
    "analytics.pointOneYoutube"  : "Need to have atleast 5 subscriber",
    "analytics.pointTwoYoutube"  : "Subscriber count are not hidden",
    "analytics.modalTitleTiktok"  : "Let's optimize your Tiktok account",
    "analytics.modalDescTiktok"  : "To be able to connect your Tiktok Account :",
    "analytics.pointOneTiktok"  : "Need to have 5 or more videos",
    "analytics.pointTwoTiktok"  : "Need to have atleast 25 followers",
    "analytics.modalTitleTwitter"  : "Let's optimize your Twitter account",
    "analytics.modalDescTwitter"  : "To be able to connect your Twitter Account :",
    "analytics.pointOneTwitter"  : "Need to have at least 5 followers",

    "analytics.notSure"  : "Not sure how to do it?",
    "analytics.seeHere"  : "See Here",

    //Pop Up
    "analytics.popup.addSocial" : "TAMBAH MEDIA SOSIAL",
    "analytics.popup.title"     : "Add Your Social Media",
};
  
export default idAnalytics;