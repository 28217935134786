/* Languange EN */

var enFooter = {
    //Footer Banner
    "footer.banner.title"           : "Want to be closer with your idol?",
    "footer.banner.desc"            : "Browse and find your idol, and share amazing experiences with your loved ones",
    "footer.banner.button"          : "Request your idol",

    //Footer Menu Banner
    "footer.menuBanner.grtTitle"     : "BintanGO 100% Guarantee",
    "footer.menuBanner.grtDesc"      : "Get your money back if your order is not process by our celebrity. We will making sure your transactions are safe.",

    //Footer Menu Link
    "footer.menuBanner.account"     : "Account",
    "footer.menuBanner.myProfile"   : "My Profile",
    "footer.menuBanner.myOrder"     : "My Order",
    "footer.menuBanner.myVideo"     : "My Video",
    "footer.menuBanner.whistlist"   : "Fan Club",
    "footer.menuBanner.promo"       : "Promo",
    "footer.menuBanner.myVoucher"   : "My Voucher",
    "footer.menuBanner.withdraw"    : "Withdraw Balance",

    //Menu NavBar
    "footer.menuBanner.myTask"              : "My Task",
    "footer.menuBanner.balance"             : "Balance",
    "footer.menuBanner.experienceSettings"  : "Fan Experience",

    "footer.menuBanner.sitemap"     : "Sitemap",
    "footer.menuBanner.aboutUs"     : "About Us",
    "footer.menuBanner.career"      : "Career",
    "footer.menuBanner.faq"         : "FAQ",
    "footer.menuBanner.blog"        : "Blog",
    "footer.menuBanner.help"        : "Help Center",
    "footer.menuBanner.privacy"     : "Privacy Policy",
    "footer.menuBanner.term"        : "Term & Condition",

    //Footer Menu NewsLetter
    "footer.menuNews.title"     : "Subscribe to our newsletter",
    "footer.menuNews.desc"      : "Be the first to know updates from celebrities",
    "footer.menuNews.email"     : "Email address",    
  };
  
export default enFooter;