/* Languange EN */

var idHomeMVP = {
    //Banner Step
  "bannerStep.chooseSelebTitle"     : "Pilih",
  "bannerStep.chooseSelebDesc"      : "celebs dan creators favorit kamu",
  "bannerStep.reqTitle"             : "Request",
  "bannerStep.reqDesc"              : "video personalisasi yang kamu inginkan",
  "bannerStep.payTitle"             : "Bayar",
  "bannerStep.payDesc"              : "menggunakan metode pembayaran yang mudah untuk kamu",
  "bannerStep.downloadTitle"        : "Download",
  "bannerStep.downloadreqDesc"      : "dan bagikan momen bahagia kamu",

  //Banner Isi
  "bannerFill.title"            : "Ciptakan Momen Tak Terlupakan Melalui Personalisasi Video",
  "bannerFill.desc"             : "Dari celeb dan creators favoritmu.",
  "bannerFill.descBanner1"      : "Kejutan Ulang Tahun",
  "bannerFill.descBanner2"      : "Dapatkan Saran dari Idola mu",
  "bannerFill.descBanner3"      : "Roasting Teman Kamu",

  //Banner Form
  "bannerForm.title"          : "Masukkan Nama Idolamu dan Dapatkan Gratis Video Shout Out!",
  "bannerForm.desc"           : "Masukkan nama idola kamu dan dapatkan kesempatan untuk memenangkan video gratis, apabila idola kamu join.",
  "bannerForm.celebName"      : "Nama celeb / creator",
  "bannerForm.userName"       : "Nama lengkap kamu",
  "bannerForm.email"          : "Email kamu",
  "bannerForm.button"         : "Masukkan request",
  "bannerForm.modelTitle"     : "Sukses",
  "bannerForm.modalDesc1"      : "Submitted! Terimakasih atas partisipasi kamu dan jangan lupa follow Instagram ",
  "bannerForm.modalIg"        : '@bintango.id',
  "bannerForm.modalDesc2"     : ' untuk informasi terupdate!',
  "bannerForm.modalClose"     : 'Tutup',

  //Banner Footer
  "bannerFooter.bannerTitle"  : "Celebrity on Demand Platform",
  "bannerFooter.bannerDesc"   : "Download the application to explore your idol celebs further and get interesting promos",
  "bannerFooter.grtTitle"     : "Garansi 100% BintanGO",
  "bannerFooter.grtDesc"      : "Dapatkan jaminan uang kembali jika pesanan Anda tidak diproses oleh  celeb atau creators. Kami akan memastikan keamanan transaksi. ",
  "bannerFooter.ts"           : "Syarat & Ketentuan",
  "bannerFooter.pp"           : "Kebijakan Privasi",


    //Menu
    "bannerFooter.info.title"  : "Informasi",
    "bannerFooter.info.ts"     : "Syarat & Ketentuan",
    "bannerFooter.info.pp"     : "Kebijakan Privasi",
    "bannerFooter.info.about"  : "Tentang Kami",
    "bannerFooter.info.regCreator" : "Register as a Creator",
  
    "bannerFooter.menu.title"  : "BintanGO",
    "bannerFooter.menu.promo"  : "Promosi",
    "bannerFooter.menu.bizz"   : "Bisnis",
    "bannerFooter.menu.blog"   : "Blog",
    "bannerFooter.menu.career" : "Karir",

  //Download
  "download.title"            : "Dapatkan pengalamanmu sendiri, sekarang!",
  "download.desc"             : "Rasakan pengalaman penggemar yang sebearnya melalui cara yang mudah dan jangkau celeb dan creators favorit kamu dengan cara yang unik dan mudah diingat.",
  };
  
export default idHomeMVP;