/* Languange EN */

var enForgotPassword = {
    "forgotPassword.form.title"           : "Reset Password",
    "forgotPassword.form.email"           : "EMAIL",
    "forgotPassword.form.button"          : "Send link",
    "forgotPassword.form.text1"           : "Masukkan e-mail yang terdaftar. Kami akan mengirimkan link untuk reset password ke e-mail kamu.",
    "forgotPassword.form.signupText"      : "Don't have account?",
    "forgotPassword.form.signupLink"      : "Signup Now",
  };
  
export default enForgotPassword;