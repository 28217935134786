/* Languange ID */

var idBrand = {
    "brand"                     : "Brand Deals",
    "brand.back"                : "Kembali ke Brand Deals",
    "brand.applied"             : "Pekerjaan yang Kamu lamar",
    "brand.appliedDesc"         : "Lihat pekerjaan yang telah Kamu lamar",
    "brand.biddingAt"           : "Penawaran di",
    "brand.announcement"        : "Tanggal Pengumuman",
    "brand.finalPrice"          : "Harga final",
    "brand.availableJobs"       : "Pekerjaan Tersedia",
    "brand.availableJobsDesc"   : "Temukan pekerjaan baru hanya untuk Kamu!",
    "brand.seeAll"              : "Lihat Semua",
    "brand.filter"              : "Filter",
    "brand.jobs"                : "Jobs",
    "brand.upcomingJobs"        : "Pekerjaan yang Akan Datang",
    "brand.category"            : "Kategori",
    "brand.platform"            : "Platform",
    "brand.estPrice"            : "Perkiraan harga",

    "brand.sort"                : "Urutkan",
    "brand.sort.closestDeadline": "Batas Waktu Terdekat",
    "brand.sort.longestDeadline": "Batas Waktu Terlama",
    "brand.sort.highPrice"      : "Harga tinggi ke rendah",
    "brand.sort.lowPrice"       : "Harga rendah ke tinggi",
    "brand.sort.clientNameA"    : "Nama klien A-Z",
    "brand.sort.clientNameZ"    : "Nama klien Z-A",

    "brand.filter.all"          : "Semua",
    "brand.filter.applied"      : "Terkirim",
    "brand.filter.client_review" : "Dalam Review",
    "brand.filter.approved"     : "Disetujui",
    "brand.filter.reject"       : "Ditolak",
    "brand.filter.finished"     : "Selesai",

    //Detail
    "brand.detail.popUPTitle"               : "Lengkapi Aplikasi Kerjamu",
    "brand.detail.popUPSubTitle"            : "Kami membutuhkan informasi tentang dirimu untuk keperluan lamaran pekerjaan-mu!",
    "brand.detail.popUPDOB"                 : "TANGGAL LAHIR",
    "brand.detail.popUPAge"                 : "UMUR KAMU",
    "brand.detail.popUPGender"              : "JENIS KELAMIN",
    "brand.detail.popUPWA"                  : "NOMOR WHATSAPP",
    "brand.detail.popUPYUser"               : "Username kamu",
    "brand.detail.popUPIG"                  : "Username Instagram",
    "brand.detail.popUPIGDesc"              : "Akun Instagram Kamu harus publik",
    "brand.detail.popUPYFollow"             : "Followers kamu",
    "brand.detail.popUPFollow"              : "Followers",
    "brand.detail.popUPYSubs"               : "Subscribers kamu",
    "brand.detail.popUPSubs"                : "Subscribers",
    "brand.detail.popUPEnterPrice"          : "Negosiasi Tarif Kerjamu",
    "brand.detail.popUPEnterPriceDesc"      : "Beri tahukan kami tarif harga yang tepat untuk diri mu dalam proyek ini, dengan melengkapi data di bawah ini",
    "brand.detail.popUPEstPrice"            : "Perkiraan Harga",
    "brand.detail.popUPErrorPrice"          : "Kamu harus memasukkan harga dalam kisaran yang  sudah ditentukan ",
    "brand.detail.popUPInfoPrice"           : "Kami akan meninjau informasi yang Kamu kirimkan",
    "brand.detail.popUPSubmittedTitle"      : "Yeyy...Selamat!!",
    "brand.detail.popUPSubmittedSubTitle1"  : "Kamu berhasil mengirimkan penawaran, kami akan memberi tahu Kamu melalui email atau pemberitahuan ",
    "brand.detail.popUPSubmittedSubTitle2"  : "3 hari",
    "brand.detail.popUPSubmittedSubTitle3"  : " setelah batas waktu pengiriman",
    "brand.detail.apply"                    : "DAFTAR",
    "brand.detail.submit"                   : "SUBMIT",
    "brand.detail.paymentStatus"            : "Payment Status",
    "brand.detail.bidTitle"                 : "Penawaran di",
    "brand.detail.ancTitle"                 : "Tanggal Pengumuman",
    "brand.detail.dealTitle"                : "Status Penawaran",
    "brand.detail.reason"                   : "Alasan",
    "brand.detail.statReject"               : "DITOLAK",
    "brand.detail.statApprove"              : "DISETUJUI",
    "brand.detail.statApplied"              : "TERKIRIM",
    "brand.detail.statFinished"             : "SELESAI",
    "brand.detail.statCReview"              : "DALAM REVIEW",
    "brand.detail.expl"                     : "CARI PENAWARAN LAIN",
    "brand.detail.connect"                  : "CONNECT",
    "brand.detail.edit"                     : "EDIT",
    "brand.detail.close"                    : "CLOSE",

    //Detail GEnder
    "brand.gender.male"                     : "Laki-Laki",
    "brand.gender.female"                   : "Perempuan",

    //Detail Main
    "brand.detail.detailEst"                : "Perkiraan Harga",
    "brand.detail.detailQual"               : "Kualifikasi Kreator",
    "brand.detail.detailCat"                : "Kategori",
    "brand.detail.detailGender"             : "Jenis Kelamin",
    "brand.detail.detailAge"                : "Umur",
    "brand.detail.detailAgeDesc"            : "Tahun",
    "brand.detail.detailLoc"                : "Lokasi",
    "brand.detail.detailFol"                : "Min. Followers",
    "brand.detail.detailFolDesc"            : "Followers",
    "brand.detail.detailQuota"              : "Kuota",
    "brand.detail.detailMoodboard"          : "Moodboard & Referensi",
    "brand.detail.regTime"                  : "Pendaftaran buka hingga",
    "brand.detail.regStart"                 : "Anda baru bisa mulai mendaftar",
    
    // Guest Footer
    "brand.detail.guestFTitle"              : "Login or Register to BintanGO",
    "brand.detail.guestFDesc"               : "Login to apply this jobs or explore other jobs",
    "brand.detail.login"                    : "LOGIN",
    "brand.detail.register"                 : "REGISTER",

    // Guest Pop Up
    "brand.detail.guestPTitle"              : "Explore more in CreatorSpace",
    "brand.detail.guestPDesc"               : "a place where everybody can be a creator. one step closer with your idol and even closer to be the next idol",

    //Detail Sosmed
    "brand.sosmed.title"                    : "Your Social Media",
    "brand.sosmed.desc"                     : "Some of your social media is still on review, so we can’t get your followers. Please input your follower",
    "brand.sosmed.successTitle"             : "Selamat! Akun {sosmed} mu telah terhubung",
    "brand.sosmed.successDesc"              : "Sekarang kamu dapat menjelajahi dan melanjutkan brand deals-mu",
    "brand.status.successTitle"             : "Selamat!\n Kamu baru saja menyelesaikan perkerjaan-mu",
    "brand.status.successDesc"              : "Pertahankan kerja bagus mu, dan jelajahi lebih banyak penawaran kerja di BintanGO",

    // Brand Task 
    "brand.task"                            : "Task",
    "brand.task.title"                      : "Mari Selesaikan Tugasmu!",
    "brand.task.descTitle"                  : "Apakah kamu siap untuk tumbuh dan berkembang? Mari jelajahi kemungkinan masa depan yang tak terbatas dengan menyelesaikan tugas di bawah ini",
    "brand.task.listTitle1"                 : "Selesaikan Tugas ",
    "brand.task.listTitle2"                 : "-mu",
    "brand.task.desc"                       : "Selesaikan tugas-mu sebelum batas waktu yang ditentukan, dengan melengkapi data dibawah ini",
    "brand.task.upload"                     : "Upload",
    "brand.task.uploadInsight"              : "Insight Post",
    "brand.task.postProof"                  : "Post Proof",
    "brand.task.postProofUrl"               : "URL",
    "brand.task.howUrlPost"                 : "How to get the URL ?",
    "brand.task.submitLinkDesc"             : "After you post the content on your social media, enter the URL of the post here ",
    "brand.task.urlPost"                    : "URL POST",
    "brand.task.urlPostPh"                  : "Your URL Post",
    "brand.task.submit"                     : "Submit",
    "brand.task.timeBefUpload"              : "You cannot upload your insight until ",
    "brand.task.imgDesc"                    : "You can start uploading your insight 7 day after you post your content",
    "brand.task.imgTitleForm"               : " POST INSIGHT SCREENSHOT",
    "brand.task.imgTitleFormNoImgTitle"     : "Upload your insight here",
    "brand.task.imgTitleFormNoImgDesc"      : "you can upload up to 3 photos",
    "brand.task.sumbmitPostTitle"           : "Selamat! Postinganmu telah terkirim dengan sukses",
    "brand.task.sumbmitPostDesc"            : "Sekarang kamu akan selalu dapat membagikan insights-mu, setelah 7 hari memposting konten atau sesuai tanggal yang ditentukan",
    "brand.task.sumbmitImageTitle"          : "Selamat! Insight-mu telah terunggah dengan sukses",
    "brand.task.sync"                       : "Also update to my creator profile",
    "brand.task.needResubmit"               : "need to resubmit your content URL",
    "brand.task.waitingApproval"            : "Waiting for approval",

    "brand.task.pu.D-3"                     : "Deadline posting 3 hari lagi",
    "brand.task.pu.D-1"                     : "Deadline posting 1 hari lagi",
    "brand.task.pu.D+1"                     : "Deadline posting sudah lewat, ayo post segera",
    "brand.task.pu.D"                       : "Deadline posting hari ini",

    "brand.task.pi.D-3"                     : "Deadline upload report insight 3 hari lagi",
    "brand.task.pi.D-1"                     : "Deadline upload report insight 1 hari lagi",
    "brand.task.pi.D+1"                     : "Deadline report insight sudah leawt, ayo upload segera",
    "brand.task.pi.D"                       : "Deadline upload report insight hari ini",

    // Brand Task URL 
    "brand.taskIGPost.1"                    : "Buka aplikasi / website Instagram",
    "brand.taskIGPost.2"                    : "Buka hasil post yang telah kamu buat untuk campaign ini",
    "brand.taskIGPost.3"                    : "Klik icon titik 3 di pojok kanan atas lalu click link / copy link",
    
    "brand.taskIGReels.1"                   : "Buka aplikasi Instagram",
    "brand.taskIGReels.2"                   : "Buka hasil IG Reels yang telah kamu buat untuk campaign ini",
    "brand.taskIGReels.3"                   : "Untuk aplikasi klik icon titik 3 di pojok kanan bawah lalu click copy link ",
    
    "brand.taskIGStory.1"                   : "Buka applikasi / website Instagram",
    "brand.taskIGStory.2"                   : "Buka hasil IG story yang telah kamu buat untuk campaign ini",
    "brand.taskIGStory.3"                   : "Untuk aplikasi klik icon titik 3 di pojok kanan bawah lalu click copy link & untuk website copy URL Websitenya",
    
    "brand.taskTiktok.1"                    : "Buka applikasi / website Tiktok",
    "brand.taskTiktok.2"                    : "Buka hasil post yang telah kamu buat untuk campaign ini",
    "brand.taskTiktok.3"                    : "Untuk applikasi klis share icon lalu klik copy link & untuk website copy url website",
    
    "brand.taskYT.1"                        : "Buka applikasi / website Youtube",
    "brand.taskYT.2"                        : "Buka video yang telah kamu buat dan upload untuk campaign ini",
    "brand.taskYT.3"                        : "Klik share icon, untuk applikasi klik copy link & untuk website klik copy.",
    
    // "brand.taskEvent.1"                     : "Task",
    // "brand.taskEvent.2"                     : "Task",
    // "brand.taskEvent.3"                     : "Task",
    
    // "brand.taskShopee.1"                    : "Task",
    // "brand.taskShopee.2"                    : "Task",
    // "brand.taskShopee.3"                    : "Task",
    
};
  
export default idBrand;