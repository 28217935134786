/* Languange ID */

var idPopUpLogin = {
    //General
    "popuLogin.formBottom.desc1"  : "Ingin menelusuri konten",
    "popuLogin.formBottom.desc2"  : "lebih banyak?",

    //Mobile
    "popuLogin.mobile.loginTitle"  : "Masuk sebagai Fans",
    "popuLogin.mobile.regisTitle"  : "Daftar sebagai Fans",
  };
  
export default idPopUpLogin;