/* Languange ID */

var idSearch = {
    //General
    "searchOverlay.desc3Message"    : "Silakan masukkan paling sedikit 3 huruf untuk mencari idola kamu",
    "searchOverlay.popSearch"       : "Pencarian Populer",
    "searchOverlay.category"        : "Kategori",
    "searchOverlay.searchbox"       : 'Coba "Ten 2 Five"',
    "searchOverlay.otherSearch"     : 'Atau mungkin kamu tertarik',

    //Not Found
    "searchOverlay.notFound.title"  : 'Pencarian tidak ditemukan',
    "searchOverlay.notFound.desc"   : 'Coba cari dengan keyword lain',
  };
  
export default idSearch;