/* Languange EN */

var enHomeMVP = {
    //Banner CD
  "bannerCD.title"  : "Personalized Fan Experiences",
  "bannerCD.title2"  : "Coming Soon!",
  "bannerCD.day"    : "DAYS",
  "bannerCD.hour"   : "HOURS",
  "bannerCD.minutes": "MINUTES",
  "bannerCD.second" : "SECONDS",

  //Banner Step
  "bannerStep.chooseSelebTitle"     : "Choose",
  "bannerStep.chooseSelebDesc"      : "your favorite celebs & creators",
  "bannerStep.reqTitle"             : "Request",
  "bannerStep.reqDesc"              : "a personalized video message ",
  "bannerStep.payTitle"             : "Pay",
  "bannerStep.payDesc"              : "with your preferred payment method",
  "bannerStep.downloadTitle"        : "Download",
  "bannerStep.downloadreqDesc"      : "and share your happiness",

  //Banner Isi
  "bannerFill.title"            : "Create Unforgettable Moments Through Personalized Videos",
  "bannerFill.desc"             : "From Your Favorite Celebs & Creators!",
  "bannerFill.descBanner1"      : "Surprise Birthday Wishes",
  "bannerFill.descBanner2"      : "Get Advice from Your Idols",
  "bannerFill.descBanner3"      : "Roast Your Friends",

  //Banner Form
  "bannerForm.title"          : "Tell Us Your Favorite Celebrity and Win a Free Video Shout Out!",
  "bannerForm.desc"           : "Submit the names of your idols and you may win a free video if they join.",
  "bannerForm.celebName"      : "Celeb/ creator name",
  "bannerForm.userName"       : "Your full name",
  "bannerForm.email"          : "Your email address",
  "bannerForm.button"         : "Submit your request",
  "bannerForm.modelTitle"     : "Success",
  "bannerForm.modalDesc1"     : "Submitted! Thank you for your participation and dont forget to follow Instagram ",
  "bannerForm.modalIg"        : '@bintango.id',
  "bannerForm.modalDesc2"     : ' for updated information!',
  "bannerForm.modalClose"     : 'Close',


  //Banner Footer
  "bannerFooter.bannerTitle"  : "Celebrity on Demand Platform",
  "bannerFooter.bannerDesc"   : "Download the application to explore your idol celebs further and get interesting promos",
  "bannerFooter.grtTitle"     : "BintanGO 100% Guarantee",
  "bannerFooter.grtDesc"      : "Get your money back if your order request is not accepted by our celebs or creators.  Your transactions are 100% safe and secure.",

  //Menu
  "bannerFooter.info.title"  : "Information",
  "bannerFooter.info.ts"     : "Terms & Condition",
  "bannerFooter.info.pp"     : "Privacy Policy",
  "bannerFooter.info.about"  : "About Us",
  "bannerFooter.info.regCreator" : "Register as a Creator",

  "bannerFooter.menu.title"  : "BintanGO",
  "bannerFooter.menu.promo"  : "Promotions",
  "bannerFooter.menu.bizz"   : "For Business",
  "bannerFooter.menu.blog"   : "Blog",
  "bannerFooter.menu.career" : "Career",

  //Download
  "download.title"            : "Get your own experiences, now!",
  "download.desc"             : "Feel the true fans experiences through simple steps and reach your favorite celeb and creators with unique and memorable ways.",
  };
  
export default enHomeMVP;