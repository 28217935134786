/* Languange ID */

var enOrder = {
    "orderRequest.request"            : "Request to",
    "orderRequest.requestVC"          : "Request Video Call for",
    "orderRequest.with"               : "with",
    "orderRequest.vcSession"          : "Video Call Session with",
    "orderRequest.videoto"            : "Who is this video for?",
    "orderRequest.other"              : "For Friend",
    "orderRequest.myself"             : "For Me",
    "orderRequest.yourName"           : "Your Name *",
    "orderRequest.recipientName"      : "Friend's Name *",
    "orderRequest.senderName"         : "Nama Pengirim *",
    "orderRequest.occasion"           : "Choose Event *",
    "orderRequest.chooseOne"          : "--Choose one--",
    "orderRequest.descVideo"          : "Your Instruction *",
    "orderRequest.phdescVideo"        : "Please describe as detail as possible for your celebrity idol. Example: Katya's birthday is in 16th, I would like you to wish her the birthday wishes! Also please end the conversation with 'Sarangheyo'",
    "orderRequest.character"          : "character",
    "orderRequest.mincharacter"       : "Please type in minimum 100 character",
    "orderRequest.submit"             : "Submit Request",
    "orderRequest.next"               : "CONTINUE",
    "orderRequest.sessionvc"          : "Select available session",
    "orderRequest.standby"            : "Standby",
    "orderRequest.standbyFrom"        : "Standby from *",
    "orderRequest.mins"               : "mins",
    "orderRequest.sold"               : "Sold",
    "orderRequest.callDuration"       : "Call Duration",
    "orderRequest.price"              : "Price",
    "orderRequest.infoTime"           : "*) All time slot are based on your local time",
    "orderRequest.agreed"             : "I have agreed to",
    "orderRequest.bintangoTC"         : "BintanGO terms & condition",

    "orderConfirmation.edit"          : "Edit",
    "orderConfirmation.to"            : "for",
    "orderConfirmation.forme"         : "Me",
    "orderConfirmation.hideMyVideo"   : "Hide video on my profile and celeb",
    "orderConfirmation.infosend"      : "Info Pengiriman",
    "orderConfirmation.standard"      : "Standard",
    "orderConfirmation.day"           : "Days",
    "orderConfirmation.hourDelivery"  : "Hour delivery",
    "orderConfirmation.getInOneDay"   : "Get it delivery in one day!",
    "orderConfirmation.summary"       : "Payment Summary",
    "orderConfirmation.promoCode"     : "Promo code",
    "orderConfirmation.shoutout"      : "BintanGO video shoutout",
    "orderConfirmation.videocall"     : "BintanGO video call",
    "orderConfirmation.discount"      : "Discount",
    "orderConfirmation.videoShoutoutFee" : "Video Shoutout Fee",
    "orderConfirmation.promotionContribution" : "Promotion Contribution",
    "orderConfirmation.nettIncome"    : "Nett Income",
    "orderConfirmation.fee"           : "Platform fee",
    "orderConfirmation.vat"           : "VAT",
    "orderConfirmation.total"         : "Total Payment",
    "orderConfirmation.nextPayment"   : "Choose Payment Method",
    "orderConfirmation.inputVoucher"  : "Input voucher code here",
    "orderConfirmation.remove"        : "Remove",
    "orderConfirmation.valid"         : "Voucher applied",
    "orderConfirmationVC.nextPayment" : "PROCEED PAYMENT",
    "orderConfirmationVC.schedule"    : "Video Call Schedule",
    "orderConfirmationVC.followTime"  : "Based on your local time",
    "orderConfirmationVC.guidelines"  : "Video Call Guidelines",
    "orderConfirmationVC.guidelines0"  : "You must download BintanGO App to do the video call",
    "orderConfirmationVC.guidelines1"  : "Add the video call schedule to your calendar once your idol accepted the request",
    "orderConfirmationVC.guidelines2"  : "Make sure your connection is stable",
    "orderConfirmationVC.guidelines3"  : "Join the video call room through the Order Detail",
    "orderConfirmationVC.guidelines4"  : "Standby in the waiting room before connecting to your idol",

    "orderPayment.infoafterPayment"   : "Silahkan cek transaksi kamu secara berkala untuk update status transaksi kamu",
    "orderPayment.backHome"           : "Kembali ke Beranda",
    "orderPayment.mytransaction"      : "Lihat Transaksi Saya",

    "orderVideoCall.waiting"          : "Video call will be done on BintanGO Apps",
    "orderVideoCall.appsLink"         : "Download Bintango Apps",
    "orderVideoCall.apps"             : "to do the video call",
  };
  
export default enOrder;