/* Languange ID */

var idForgotPassword = {
    "forgotPassword.form.title"           : "Atur Ulang Kata Sandi",
    "forgotPassword.form.email"           : "EMAIL",
    "forgotPassword.form.button"          : "Kirim link",
    "forgotPassword.form.text1"           : "Masukkan e-mail yang terdaftar. Kami akan mengirimkan link untuk reset password ke e-mail kamu.",
    "forgotPassword.form.signupText"      : "Belum punya akun?",
    "forgotPassword.form.signupLink"      : "Daftar sekarang",
  };
  
export default idForgotPassword;