import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import FooterTalent from "../../components/footer/talent";

class FooterMVP extends Component {
    render() {
        return (
            <div>
            {/* <div className="for-desktop">
                <FooterTalent />
            </div> */}
            {/* <div className="for-mobile static-page-request-download">
                <div className="container-bintango justify-content-center">
                    <div className="col-12 col-img-info-download">
                        <h1><FormattedMessage id="home.download.title" /></h1>
                        <p><FormattedMessage id="home.download.desc" /></p>
                    </div>
                </div>
                <div className="d-flex pb-5">
                    <div className="col-6"><a href="https://play.google.com/store/apps/details?id=id.bintango" target="_blank" className="mr-4"><img src="/assets/img/playstore.svg" width="100%" alt="Logo" /></a></div>
                    <div className="col-6"><a href="https://apps.apple.com/us/app/bintango/id1576150052" target="_blank"><img src="/assets/img/appstore.svg" width="100%" alt="Logo" /></a></div>
                </div>
            </div> */}
            <footer id="mvp">
                <div className="container-full-bintango">
                    <div className="row">
                    <div className="col-12 col-lg-4 text-center text-lg-left">
                        <img src="/assets/img/bintango-logo-white.svg" className="logo-footer" alt="Logo" />        
                        <div className="category-desc all-footer-font py-3 pb-4">
                            <div className="category-title all-footer-font-title"><FormattedMessage id="bannerFooter.bannerTitle" /></div>
                            <FormattedMessage id="bannerFooter.bannerDesc" />
                        </div>
                        <div className="d-flex">
                            <div className="w-100 mr-3" style={{maxWidth: "160px"}}>
                                <a href="https://play.google.com/store/apps/details?id=id.bintango" target="_blank" className="mr-4">
                                    <img src="/assets/img/playstore.svg" width="100%" alt="Logo" style={{maxWidth: "160px"}} />
                                </a>
                            </div>
                            <div className="w-100" style={{maxWidth: "160px"}}>
                                <a href="https://apps.apple.com/us/app/bintango/id1576150052" target="_blank">
                                    <img src="/assets/img/appstore.svg" width="100%" alt="Logo" style={{maxWidth: "160px"}} />
                                </a>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-12 col-lg-4">
                        <span className="d-flex py-3 pb-5 py-lg-0">
                            <span className="block-footer-img">
                                <img src="/assets/img/guarantee.svg" width="100%" />
                            </span>
                            <div className="block-footer ">
                                <div className="category-title all-footer-font-title"><FormattedMessage id="bannerFooter.grtTitle" /></div>
                                <div className="category-desc all-footer-font"><FormattedMessage id="bannerFooter.grtDesc" /></div>
                            </div>
                        </span>
                    </div> */}
                    <div className="col-12 col-lg-4 text-center text-lg-left mt-5 mt-lg-0">
                        <div className="row">
                        <div className="col-6">
                            <div className="category-title all-footer-font-title pb-4"><FormattedMessage id="bannerFooter.info.title" /></div>
                            <div className="category-desc all-footer-font pb-3 pl-lg-3">
                                <a href="https://blog.bintango.com/page/tentang-kami/"><FormattedMessage id="bannerFooter.info.about" /></a>
                            </div>
                            <div className="category-desc all-footer-font pb-3 pl-lg-3 text-center text-lg-left">
                                <Link to="/condition"><FormattedMessage id="bannerFooter.info.ts" /></Link>
                            </div>
                            <div className="category-desc all-footer-font pb-3 pl-lg-3 text-center text-lg-left">
                                <Link to="/privacy"><FormattedMessage id="bannerFooter.info.pp" /></Link>
                            </div>
                            <div className="category-desc all-footer-font pb-3 pl-lg-3 text-center text-lg-left">
                                <a href="https://biz.bintango.com/pages/registration-form" target="_blank"><FormattedMessage id="bannerFooter.info.regCreator" /></a>
                            </div>
                        </div>
                        
                        <div className="col-6">
                            <div className="category-title all-footer-font-title pb-4"><FormattedMessage id="bannerFooter.menu.title" /></div>
                            <div className="category-desc all-footer-font pb-3 pl-lg-3">
                                <a href="https://blog.bintango.com/page/promo/	"><FormattedMessage id="bannerFooter.menu.promo" /></a>
                            </div>
                            <div className="category-desc all-footer-font pb-3 pl-lg-3 text-center text-lg-left">
                                <a href="https://biz.bintango.com/"><FormattedMessage id="bannerFooter.menu.bizz" /></a>
                            </div>
                            <div className="category-desc all-footer-font pb-3 pl-lg-3 text-center text-lg-left">
                                <a href="https://blog.bintango.com"><FormattedMessage id="bannerFooter.menu.blog" /></a>
                            </div>
                            <div className="category-desc all-footer-font pb-3 pl-lg-3 text-center text-lg-left">
                                <a href="https://www.kalibrr.com/c/bintango/jobs"><FormattedMessage id="bannerFooter.menu.career" /></a>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="col-12 col-lg-4 mt-4 mt-lg-0">
                        <div className="category-title all-footer-font-title pb-4">PT Stargo Digital Indonesia</div>
                        <div className="d-flex pb-4">
                            <div className="pr-2"><img src="/assets/img/mail.svg" /></div>
                            <div className="category-desc all-footer-font">info@bintango.id</div>
                        </div>
                        <div className="d-flex pb-4">
                            <div className="pr-2"><img src="/assets/img/call-phone.svg" /></div>
                            <div className="category-desc all-footer-font">+6281280777870</div>
                        </div>
                        <div className="d-flex pb-4">
                            <div className="pr-2"><img src="/assets/img/pin.svg" /></div>
                            <div className="category-desc all-footer-font">Cyber 2 Tower, Lantai 31, Jl. H. Rasuna Said Kuningan, Setiabudi, Jakarta Selatan, DKI Jakarta</div>
                        </div>
                    </div>
                    <div className="w-100 mt-3 mb-3">
                        <div className="footer-separator"></div>
                    </div>
                    <div className="col-12 text-center text-lg-right">
                        <div className="row pb-2">
                            <div className="col-lg-6 pb-2 pb-lg-0">
                                <ul className="social-media mt-0">
                                    <li><a href="https://www.instagram.com/bintango.id/" target="_blank"><img src="/assets/img/instagram.svg" alt="Logo" /></a></li>
                                    <li><a href="https://www.facebook.com/BintanGO-103117912016613" target="_blank"><img src="/assets/img/facebook.svg" alt="Logo" /></a></li>
                                    <li><a href="https://www.linkedin.com/company/bintango/mycompany/ " target="_blank"><img src="/assets/img/linkedin.svg" alt="Logo" /></a></li>
                                    <li><a href="https://api.whatsapp.com/send?phone=6281280777870" target="_blank"><img src="/assets/img/whatsapp.svg" alt="Logo" /></a></li>
                                    <li>
                                        {/* <a href="https://www.youtube.com/channel/UCXy9MiaE5Hs-D69YYeamXpw" target="_blank"><img src="/assets/img/youtube.svg" alt="Logo" /></a> */}
                                        <img src="/assets/img/youtube.svg" alt="Logo" />
                                    </li>
                                    <li><a href="https://www.tiktok.com/@bintango.id" target="_blank"><img src="/assets/img/tiktok.svg" alt="Logo" /></a></li>
                                </ul>
                            </div>
                            <div className="col-lg-6 pb-2 pb-lg-0">
                                <div className="all-footer-font pt-2 "><b>Copyright 2021 - PT Stargo Digital Indonesia</b></div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </footer>
            </div>
        );
    }
}

export default FooterMVP;