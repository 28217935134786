import { createSlice } from "@reduxjs/toolkit";

var userData = localStorage.getItem('userData');
var initialState = {};
// console.log(JSON.parse(userData));
try {
    initialState = { 
        isLogin: localStorage.getItem('isLogin'), 
        accessToken: localStorage.getItem('accessToken'),
        user: userData === '' ? '' : JSON.parse(userData) 
        // user: '' 
    };
} catch(e) {
    initialState = { 
        isLogin: "false", 
        accessToken: '',
        // user: userData === '' ? '' : JSON.parse(userData) 
        user: '' 
    }; 
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login(state, action) {
            return {
                ...state,
                isLogin: "true",
                accessToken: action.payload.accessToken,
                user: action.payload.user,
            };
        },
        logout(state) {
            return {
                ...state,
                isLogin: "false",
                accessToken: '',
                user: {},
            };
        },
        updateUser(state, action) {
            var data = action.payload;
            var jsonData = JSON.parse(localStorage.getItem('userData'));
            // console.log("updateUser:" + data);
            var users = {
                // ...action.payload,
                "fullname" : data.fullname !== null && data.fullname !== undefined ? data.fullname : jsonData.fullname,
                "ppUrl_celeb" : data.ppUrl_celeb !== null && data.ppUrl_celeb !== undefined ? data.ppUrl_celeb : jsonData.ppUrl_celeb,
                "ppUrl" : data.ppUrl !== null && data.ppUrl !== undefined ? data.ppUrl : jsonData.ppUrl,
                "username" : data.username !== null && data.username !== undefined ? data.username : jsonData.username,
                "email" : data.email !== null && data.email !== undefined ? data.email : jsonData.email,
                "bday" : data.bday !== null && data.bday !== undefined ? data.bday : jsonData.bday,
                "fullname_celeb" : data.fullname_celeb !== null && data.fullname_celeb !== undefined ? data.fullname_celeb : jsonData.fullname_celeb,
                "username_celeb" : data.username_celeb !== null && data.username_celeb !== undefined ? data.username_celeb : jsonData.username_celeb,
                "bday_celeb" : data.bday_celeb !== null && data.bday_celeb !== undefined ? data.bday_celeb : jsonData.bday_celeb,
                "verified_celeb" : data.verified_celeb !== null && data.verified_celeb !== undefined ? data.verified_celeb : jsonData.verified_celeb,
                "role" : jsonData.role,
                "insert_date" : jsonData.insert_date,
            };

            localStorage.setItem('userData', JSON.stringify(users));

            return {
                ...state,
                user: users,
            };
        },
    },
});

export const { login, logout, updateUser } = authSlice.actions;
export default authSlice.reducer;