import React, { Component } from 'react';
import { Link } from "react-router-dom";

export default class ModalGoodbye extends Component {
  async componentDidMount() {
    document.body.style.overflow = 'hidden'
  }

  render() {
      return(
        <div className="modal show d-block" id="categoryAll" data-keyboard="false" data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="categoryAllLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-googbye modal-dialog-centered" role="document">
            <div className="bg-goodbye">
              <div className="hello text-center">Hello 👋,</div>
              <div className="modal-content modal-goodbye">
                <img className="kutip-goodbye" src="/assets/img/kutip.svg" />
                Terima kasih untuk ketertarikannya dalam jasa Fans kami, sayangnya, kami sedang mengerahkan fokus kami dalam area lain dalam Ekonomi Kreator, dan oleh karena itu, kami telah menonaktifkan segmen situs fan kami.
                <br/><br/>Kami ingin sekali mengajakmu untuk melihat apa saja yang kami sedang kerjakan, dan bergabung dengan kami untuk memberdayakan Kreator Konten Indonesia dan mengamplifikasi brand lokal. Yuk, cek segmen situs lain!
                <div className="row justify-content-center">
                  <div className='col-lg-6 mt-4'>
                    <a href="https://bintango.com/creator" target="_blank">
                      <div className="button-goodbye-redirect">
                        <span className='title'>Kamu seorang kreator?</span>
                        <span className='desc'>Cek apa yang kami tawarkan untuk mendukung kamu sebagai kreator</span>
                      </div>
                    </a>
                  </div>
                  <div className='col-lg-6 mt-4'>
                    <a href="https://bintango.com/brand" target="_blank">
                      <div className="button-goodbye-redirect">
                        <span className='title'>Kamu sebuah brand?</span>
                        <span className='desc'>Cek apa yang kami tawarkan untuk mendukung kamu sebagai brand</span>
                      </div>
                    </a>
                  </div>
                  <div className='col-lg-8 mt-4'>
                    <a href="https://bintango.com/" target="_blank">
                      <div className="button-goodbye-redirect">
                        <span className='title'>Ingin tau lebih banyak?</span>
                        <span className='desc'>Kunjungi website Kami untuk mengetahui apa saja yang Kami tawarkan untuk Kamu</span>
                      </div>
                    </a>
                  </div>
                </div>
                <hr className="hr-goodbye"/>
                <div className='footer-goodbye text-center'>Punya pertanyaan untuk jasa Fans BintanGO Kami atau yang lainnya?</div>
                <a href="https://wa.me/6281280777870" target="_blank"><div className='contact-goodbye text-center'>Hubungi Kami</div></a>
              </div>
            </div>
          </div>
        </div>
      ); 
  }
}