/* Languange ID */

var idSearchCategory = {
    "searchCat.leftMenu.category"         : "Kategori",
    "searchCat.leftMenu.all"              : "Tampilkan Semua",
    "searchCat.leftMenu.allPrice"         : "Semua Harga",

    //Sort
    "searchCat.leftMenu.sort"             : "Urutkan Berdasarkan",
    "searchCat.leftMenu.sortList1"        : "Paling populer",
    "searchCat.leftMenu.sortList2"        : "Harga tinggi ke rendah",
    "searchCat.leftMenu.sortList3"        : "Harga rendah ke tinggi",
    "searchCat.leftMenu.sortList4"        : "Nama alfabetikal A-Z",
    "searchCat.leftMenu.sortList5"        : "Nama alfabetikal Z-A",
    "searchCat.leftMenu.sortList6"        : "Jumlah Review",

    //Price
    "searchCat.leftMenu.price"             : "Kisaran Harga",
    "searchCat.leftMenu.minprice"          : "HARGA MINIMAL",
    "searchCat.leftMenu.maxprice"          : "HARGA MAKSIMAL",
    "searchCat.leftMenu.0-250k"            : "Rp 0 - Rp 250,000",
    "searchCat.leftMenu.250k-500k"         : "Rp 250,001 - Rp 500,000",
    "searchCat.leftMenu.500k-1jt"          : "Rp 500,001 - Rp 1,000,000",
    "searchCat.leftMenu.1jt-5jt"           : "Rp 1,000,001 - Rp 5,000,000",
    "searchCat.leftMenu.5jt++"             : "> Rp 5,000,000",

    //Time
    "searchCat.leftMenu.time"             : "Waktu Pengerjaan",
    "searchCat.leftMenu.timeList1"        : "Pengerjaan 24 Jam",
    "searchCat.leftMenu.timeList2"        : "1 - 3 Hari",
    "searchCat.leftMenu.timeList3"        : "4 - 7 Hari",
    "searchCat.leftMenu.timeList4"        : "8 - 14 Hari",
    "searchCat.leftMenu.timeList5"        : "> 14 Hari",

    //Banner
    "searchCat.leftMenu.banner1"        : "Kejutan Ulang Tahun",
    "searchCat.leftMenu.banner2"        : "Dapatkan Saran dari Idola mu",
    "searchCat.leftMenu.banner3"        : "Roasting Teman Kamu",

    //Button
    "searchCat.button.reset"            : "Reset",
    "searchCat.button.filter"           : "Terapkan",

    //Title
    "searchCat.title"                   : "Hasil Pencarian untuk ",

    //Search Not Found
    "searchCat.notFound.title"          : "Hasil Pencarian",
    "searchCat.notFound.desc1"          : "Pencarian tidak ditemukan",
    "searchCat.notFound.desc2"          : "Tapi kami punya rekomendasi seleb yang mungkin cocok untuk kamu",
  };
  
export default idSearchCategory;