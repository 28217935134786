/* Languange ID */

var enBrand = {
    "brand"                     : "Brand Deals",
    "brand.back"                : "Back to Brand Deals",
    "brand.applied"             : "Applied Jobs",
    "brand.appliedDesc"         : "The jobs you applied can be check here",
    "brand.biddingAt"           : "Bidding at",
    "brand.announcement"        : "Announcement Date",
    "brand.finalPrice"          : "Final Price",
    "brand.availableJobs"       : "Available Jobs",
    "brand.availableJobsDesc"   : "Take the opportunity to collaborate with big brands",
    "brand.seeAll"              : "See All",
    "brand.filter"              : "Filter",
    "brand.jobs"                : "Jobs",
    "brand.upcomingJobs"        : "Upcoming Jobs",
    "brand.category"            : "Category",
    "brand.platform"            : "Platform",
    "brand.estPrice"            : "Est price",

    "brand.sort"                : "Sort",
    "brand.sort.closestDeadline": "Closest Deadline",
    "brand.sort.longestDeadline": "Longest Deadline",
    "brand.sort.highPrice"      : "High to low price",
    "brand.sort.lowPrice"       : "Low to high price",
    "brand.sort.clientNameA"    : "Client name A-Z",
    "brand.sort.clientNameZ"    : "Client name Z-A",

    "brand.filter.all"          : "All Status",
    "brand.filter.applied"      : "Applied",
    "brand.filter.client_review" : "Client Review",
    "brand.filter.approved"     : "Approved",
    "brand.filter.reject"       : "Reject",
    "brand.filter.finished"     : "Finished",

    //Detail
    "brand.detail.popUPTitle"               : "Complete Your Job  Application",
    "brand.detail.popUPSubTitle"            : "We need information about you for your job application purposes!",
    "brand.detail.popUPDOB"                 : "DOB",
    "brand.detail.popUPAge"                 : "YOUR AGE",
    "brand.detail.popUPGender"              : "YOUR GENDER",
    "brand.detail.popUPWA"                  : "WHATSAPP NUMBER",
    "brand.detail.popUPYUser"               : "Your Username",
    "brand.detail.popUPIG"                  : "Your Instagram username",
    "brand.detail.popUPIGDesc"              : "You need to have public Instagram account",
    "brand.detail.popUPYFollow"             : "Your followers",
    "brand.detail.popUPYFollow"             : "Your followers",
    "brand.detail.popUPFollow"              : "Followers",
    "brand.detail.popUPYSubs"               : "Your subscribers",
    "brand.detail.popUPSubs"                : "Subscribers",
    "brand.detail.popUPEnterPrice"          : "Negotiate Your Job Rate",
    "brand.detail.popUPEnterPriceDesc"      : "Let us know the exact price rate for you in this project, by completing the data below",
    "brand.detail.popUPEstPrice"            : "Est Price",
    "brand.detail.popUPErrorPrice"          : "You must enter a price within the range specified in Est. Price",
    "brand.detail.popUPInfoPrice"           : "We will review the information  that you are submitted",
    "brand.detail.popUPSubmittedTitle"      : "Yeyy...Congratzz",
    "brand.detail.popUPSubmittedSubTitle1"  : "You have successfully submitted your deals, we will notify you via email or notification ",
    "brand.detail.popUPSubmittedSubTitle2"  : "3 days",
    "brand.detail.popUPSubmittedSubTitle3"  : " after the submission deadline",
    "brand.detail.apply"                    : "Apply",
    "brand.detail.submit"                   : "SUBMIT",
    "brand.detail.paymentStatus"            : "Payment Status",
    "brand.detail.bidTitle"                 : "Bidding At",
    "brand.detail.ancTitle"                 : "Announcement Date",
    "brand.detail.dealTitle"                : "Deals Status",
    "brand.detail.reason"                   : "Reason",
    "brand.detail.statReject"               : "REJECTED",
    "brand.detail.statApprove"              : "APPROVED",
    "brand.detail.statApplied"              : "APPLIED",
    "brand.detail.statFinished"             : "FINISHED",
    "brand.detail.statCReview"              : "CLIENT REVIEW",
    "brand.detail.expl"                     : "EXPLORE MORE DEALS",
    "brand.detail.connect"                  : "CONNECT",
    "brand.detail.edit"                     : "EDIT",
    "brand.detail.close"                    : "CLOSE",

    //Detail GEnder
    "brand.gender.male"                     : "Male",
    "brand.gender.female"                   : "Female",
    
    //Detail Main
    "brand.detail.detailEst"                : "Est price",
    "brand.detail.detailQual"               : "Creator Qualification",
    "brand.detail.detailCat"                : "Categories",
    "brand.detail.detailGender"             : "Gender",
    "brand.detail.detailAge"                : "Age",
    "brand.detail.detailAgeDesc"            : "Years Old",
    "brand.detail.detailLoc"                : "Location",
    "brand.detail.detailFol"                : "Min. Followers",
    "brand.detail.detailFolDesc"            : "Followers",
    "brand.detail.detailQuota"              : "Quota",
    "brand.detail.detailMoodboard"          : "Moodboard & References",
    "brand.detail.regTime"                  : "Register open until",
    "brand.detail.regStart"                 : "You can start registering on",

    // Guest Footer
    "brand.detail.guestFTitle"              : "Login or Register to BintanGO",
    "brand.detail.guestFDesc"               : "Login to apply this jobs or explore other jobs",
    "brand.detail.login"                    : "LOGIN",
    "brand.detail.register"                 : "REGISTER",
    
    // Guest Pop Up
    "brand.detail.guestPTitle"              : "Explore more in CreatorSpace",
    "brand.detail.guestPDesc"               : "a place where everybody can be a creator. one step closer with your idol and even closer to be the next idol",

    //Detail Sosmed
    "brand.sosmed.title"                    : "Your Social Media",
    "brand.sosmed.desc"                     : "Some of your social media is still on review, so we can’t get your followers. Please input your follower",
    "brand.sosmed.successTitle"             : "Congratulations! Your {sosmed} account is connected",
    "brand.sosmed.successDesc"              : "Now you can explore and continue your brand deals",
    "brand.status.successTitle"             : "Congratulations!\n You just finished your job",
    "brand.status.successDesc"              : "Keep it up your good work, and explore more job offers on BintanGO",
    
    // Brand Task 
    "brand.task"                            : "Task",
    "brand.task.title"                      : "Let’s Finish Your Task!",
    "brand.task.descTitle"                  : "Are you ready to grow and develop? Let's explore the endless possibilities of the future by completing the tasks below",
    "brand.task.listTitle1"                 : "Finish Your ",
    "brand.task.listTitle2"                 : " Task",
    "brand.task.desc"                       : "Finish your task before the deadline time, by completing the data below",
    "brand.task.upload"                     : "Upload",
    "brand.task.uploadInsight"              : "Insight Post",
    "brand.task.postProof"                  : "Post Proof",
    "brand.task.postProofUrl"               : "URL",
    "brand.task.howUrlPost"                 : "How to get the URL ?",
    "brand.task.submitLinkDesc"             : "After you post the content on your social media, enter the URL of the post here ",
    "brand.task.urlPost"                    : "URL POST",
    "brand.task.urlPostPh"                  : "Your URL Post",
    "brand.task.submit"                     : "Submit",
    "brand.task.timeBefUpload"              : "You cannot upload your insight until ",
    "brand.task.imgDesc"                    : "You can start uploading your insight 7 day after you post your content",
    "brand.task.imgTitleForm"               : " POST INSIGHT SCREENSHOT",
    "brand.task.imgTitleFormNoImgTitle"     : "Upload your insight here",
    "brand.task.imgTitleFormNoImgDesc"      : "you can upload up to 3 photos",
    "brand.task.sumbmitPostTitle"           : "Congratulations! Your post has been submitted successfully",
    "brand.task.sumbmitPostDesc"            : "Now you will always be able to share your insights, after 7 days of posting your content or as per a specified date",
    "brand.task.sumbmitImageTitle"          : "Congratulations! Your insight has been uploaded successfully",
    "brand.task.sync"                       : "Also update to my creator profile",
    "brand.task.needResubmit"               : "need to resubmit your content URL",
    "brand.task.waitingApproval"            : "Waiting for approval",

    "brand.task.pu.D-3"                     : "Post Deadline is in 3 days left",
    "brand.task.pu.D-1"                     : "Post Deadline is in 1 day left",
    "brand.task.pu.D+1"                     : "Post Deadline is passed, let’s put the URL now",
    "brand.task.pu.D"                       : "Post Deadline is Today.",

    "brand.task.pi.D-3"                     : "Deadline upload insight report is in 3 days left",
    "brand.task.pi.D-1"                     : "Deadline upload insight report is in 1 day left",
    "brand.task.pi.D+1"                     : "Deadline upload insight report is passed, let’s put the URL now",
    "brand.task.pi.D"                       : "Deadline upload insight report is Today.",
    
    
    // Brand Task URL 
    "brand.taskIGPost.1"                    : "Open Instagram app/website",
    "brand.taskIGPost.2"                    : "Open the results of the posts you have made for this campaign",
    "brand.taskIGPost.3"                    : "Click the 3 dot icon in the upper right corner then click link / copy link",
    
    "brand.taskIGReels.1"                   : "Open Instagram app",
    "brand.taskIGReels.2"                   : "Open the IG Reels results that you have created for this campaign",
    "brand.taskIGReels.3"                   : "For applications, click the 3 dot icon in the lower right corner then click copy link.",
    
    "brand.taskIGStory.1"                   : "Open Instagram app/website",
    "brand.taskIGStory.2"                   : "Open the IG story results that you have created for this campaign",
    "brand.taskIGStory.3"                   : "For applications, click the 3 dot icon in the lower right corner then click copy link & for websites copy the website URL",
    
    "brand.taskTiktok.1"                    : "Open the Tiktok app/website",
    "brand.taskTiktok.2"                    : "Open the results of the posts you have made for this campaign",
    "brand.taskTiktok.3"                    : "For applications, click the share icon, then click copy link & for websites, copy the website url",
    
    "brand.taskYT.1"                        : "Open the Youtube app/website",
    "brand.taskYT.2"                        : "Open the video that you have created and uploaded for this campaign",
    "brand.taskYT.3"                        : "Click the share icon, for applications click copy link & for websites click copy.",
    
    // "brand.taskEvent.1"                     : "Task",
    // "brand.taskEvent.2"                     : "Task",
    // "brand.taskEvent.3"                     : "Task",
    
    // "brand.taskShopee.1"                    : "Task",
    // "brand.taskShopee.2"                    : "Task",
    // "brand.taskShopee.3"                    : "Task",
};
  
export default enBrand;