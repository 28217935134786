import React, { Component } from "react";
import ModalGoodbye from '../container/modalGoodbye';
// import Footer from "./partial/Footer";
// import Footer from "./partial/FooterMVP";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default class AppLayout extends Component {
  render() {
    // const { containerClassnames } = this.props;
    
    return (
      <div 
        id="app-container" 
        // className={containerClassnames}
      >                  
        { this.props.children }      
        <ModalGoodbye /> 
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    );
  }
}