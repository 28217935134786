import appLocaleData from 'react-intl/locale-data/es';
import idID from '../locales/idID';
import idFooter from '../locales/element/idFooter';
import idNavBar from '../locales/element/idNavBar';
import idPopUpLogin from '../locales/element/idPopUpLogin';
import idSearch from '../locales/element/idSearch';
import idPopUpVoucher from '../locales/element/idPopUpVoucher';
import idPopUpNotifApps from '../locales/element/idPopUpNotifApps';
import idPopUpPurchaseStep from '../locales/element/idPopUpPurchaseStep';
import idHome from '../locales/pages/idHome';
import idHomeMVP from '../locales/pages/idHomeMVP';
import idDetailArtist from '../locales/pages/idDetailArtist';
import idSearchCategory from '../locales/pages/idSearchCategory';
import idRegister from '../locales/pages/idRegister';
import idLogin from '../locales/pages/idLogin';
import idVerify from '../locales/pages/idVerify';
import idForgotPassword from '../locales/pages/idForgotPassword';
import idPrivacy from '../locales/pages/idPrivacy';
import idTermCondition from '../locales/pages/idTermCondition';
import idBizzInquiry from '../locales/pages/idBizzInquiry';
import idOrder from '../locales/pages/idOrder';
import idDashboard from '../locales/pages/idDashboard';
import idFAQ from '../locales/pages/idFAQ';
import idWithdraw from '../locales/pages/idWithdraw';
import idDownloadApps from '../locales/pages/idDownloadApps';
import idBioDesign from '../locales/pages/idBioDesign';
import idBioManage from '../locales/pages/idBioManage';
import idBioMyLink from '../locales/pages/idBioMyLink';
import idInvoiceStep from '../locales/pages/idInvoiceStep';
import idInvoice from '../locales/pages/idInvoice';
import idFinance from '../locales/pages/idFinance';
import idBrand from '../locales/pages/idBrand';
import idMission from '../locales/pages/idMission';
import idCelebRegist from '../locales/pages/idCelebRegist';
import idWallet from '../locales/pages/idWallet';
import idAnalytics from '../locales/pages/idAnalytics';

const IdLang = {
    messages: {
        ...idID,
        ...idFooter,
        ...idNavBar,
        ...idHome,
        ...idHomeMVP,
        ...idDetailArtist,
        ...idSearchCategory,
        ...idSearch,
        ...idPopUpLogin,
        ...idRegister,
        ...idLogin,
        ...idVerify,
        ...idForgotPassword,
        ...idPrivacy,
        ...idTermCondition,
        ...idBizzInquiry,
        ...idOrder,
        ...idDashboard,
        ...idFAQ,
        ...idWithdraw,
        ...idPopUpVoucher,
        ...idPopUpNotifApps,
        ...idDownloadApps,
        ...idPopUpPurchaseStep,
        ...idBioDesign,
        ...idBioManage,
        ...idBioMyLink,
        ...idInvoiceStep,
        ...idInvoice,
        ...idFinance,
        ...idBrand,
        ...idMission,
        ...idCelebRegist,
        ...idWallet,
        ...idAnalytics,
    },
    locale: 'en-ID',
    data: appLocaleData
};
export default IdLang;