/* Languange EN */

var idDetailArtist = {
    /* 00.Example */
    "testData": "Homes",
    
    //Profile
    "detailArtist.profile.fans"         : "Fan",
    "detailArtist.profile.rating"       : "Rating",
    "detailArtist.profile.deliv"        : "Waktu Pengerjaan",
    "detailArtist.profile.delivStat"    : "hari",
    "detailArtist.profile.price"        : "Harga",
    "detailArtist.profile.videoPrice"   : "Harga",
    "detailArtist.profile.video"        : "Video Shoutout",
    "detailArtist.profile.videoDisable" : " Layanan ini tidak tersedia",
    "detailArtist.profile.videoReq"     : "AJAK",
    "detailArtist.profile.videoReqAct"  : "PESAN",
    "detailArtist.profile.business"     : "Pertanyaan Bisnis",
    "detailArtist.profile.contact"      : "Kontak Kami",
    "detailArtist.profile.videoCall"    : "Video Call",
    "detailArtist.profile.videoCallDesc": "Menuju Aplikasi",
    "detailArtist.profile.videoCallReq" : "PILIH JADWAL",
    "detailArtist.profile.videoCallDur" : "Durasi",
    "detailArtist.profile.videoCallStd" : "Persiapan",
    "detailArtist.profile.disable"      : "TIDAK TERSEDIA",
    "detailArtist.profile.holidayPp"    : " sedang libur saat ini.",

    //Shootout
    "detailArtist.shootout.title"       : "BintanGO Shoutout Terbaik ",
    "detailArtist.shootout.subtitle"    : "Video Shoutout yang sebelumnya dibuat oleh ",

    //More Celeb
    "detailArtist.moreCeleb.title"      : "Celeb lain untuk disukai",

    //Overlay join desc
    "detailArtist.overlay.title"       : "Mau lihat lebih banyak?",
    "detailArtist.overlay.desc1"       : "Gabung dengan fans club ",
    "detailArtist.overlay.desc2"       : " untuk melihat lebih banyak video?",
    "detailArtist.overlay.join"        : "Gabung",

    //Fans
    "detailArtist.fans.join"            : "Gabung Fan Club",
    "detailArtist.fans.unjoin"          : "Berhenti menjadi fan",
    "detailArtist.fans.successjoin"     : "Berhasil bergabung ke fan club",
    "detailArtist.fans.successunjoin"   : "Berhasil berhenti menjadi fan",

    //Tooltip
    "detailArtist.tooltip.vshootout"    : "Video yang ditampilkan sudah disetujui oleh user",

    //Menu Dynamic
    "detailArtist.dynamic.vcTitle"      : "Dapatkan video shoutout khusus untukmu",
    "detailArtist.dynamic.vcDesc"       : " akan memberikan pesan dan ucapan di momen spesial kamu",
    "detailArtist.dynamic.order"        : "Bagaimana cara memesan?",
    "detailArtist.dynamic.vcAppsDesc"   : "Video Call akan dilakukan di Aplikasi BintanGO",
    "detailArtist.dynamic.mins"         : " menit",
  };
  
export default idDetailArtist;