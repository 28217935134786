/* Languange ID */

var idID = {
  /* 00.Example */
  
  
  /* 01.General */
  "general.showAll"         : "Lihat Semua",
  "general.category"        : "Kategori",
  "general.showMore"        : "Tampilkan Lebih Banyak",
  "general.or"              : "Atau",
  "general.of"              : "dari",
  "general.tryAgain"        : "COBA LAGI",
  "general.close"           : "TUTUP",

  //Misc
  "general.topBannerPromo"  : "% Diskon 20% untuk pemesanan video shoutout pertama kali*",

  //Video
  "general.video.share"       : "Bagikan",
  "general.video.download"    : "Download",
  "general.video.report"      : "Laporkan",
  "general.video.reasonTitle" : "Alasan laporkan video ",
  "general.video.reasonBtn"   : "Laporkan",

  //Celeb
  "general.celeb.with"        : " dengan ",
  "IDR"                       : "Rp ",
  
  //Form Error Validation
  "errMsgReq"                 : "Required.",
  "errMsgEmail"               : "Email tidak valid."
};

export default idID;
