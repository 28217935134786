/* Languange EN */

var enLogin = {
    "login.form.title"           : "Log In",
    "login.form.email"           : "EMAIL",
    "login.form.password"        : "PASSWORD",
    "login.form.button"          : "Enter",
    "login.form.otherLoginText"  : "Or Sign In with",
    "login.form.signupText"      : "Don't have an account?",
    "login.form.signupLink"      : "Sign Up Now",
    "login.form.errButton"       : "Continue",
    "login.form.forgotPassword"  : "Forgot password",
    "login.form.error1"  : "Transaction failed. Invalid username or password.",
  };
  
export default enLogin;