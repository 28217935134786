/* Languange ID */

var idPopUpNotifApps = {
    //General
    "popupNotifApps.form.button"   : "DOWNLOAD SEKARANG",

    //VC
    "popupNotifApps.vc.title"    : "Kita akan sampaikan permintaanmu!",
    "popupNotifApps.vc.desc1"    : "Kami akan menyampaikan ke ",
    "popupNotifApps.vc.desc2"    : " kalau kamu mau melakukan video call. Ayo unduh aplikasi BintanGO untuk memesan video call dengan idola lainnya sekarang!",

    //VSH
    "popupNotifApps.vsh.title"    : "Kita akan sampaikan permintaanmu!",
    "popupNotifApps.vsh.desc1"    : "Kami akan menyampaikan ke ",
    "popupNotifApps.vsh.desc2"    : " kalau kamu mau memesan video ucapan. Ayo unduh aplikasi BintanGO untuk memesan video ucapan lebih mudah!",
  };
  
export default idPopUpNotifApps;