/* Languange EN */

var enSearch = {
    //General
    "searchOverlay.desc3Message"    : "Please enter at least 3 letters to find your idol",
    "searchOverlay.popSearch"       : "Popular Search",
    "searchOverlay.category"        : "Category",
    "searchOverlay.searchbox"       : 'Try "Ten 2 Five"',
    "searchOverlay.otherSearch"     : "Or maybe you're interested",

    //Not Found
    "searchOverlay.notFound.title"  : 'Search not found',
    "searchOverlay.notFound.desc"   : 'Try searching with other keywords',
  };
  
export default enSearch;