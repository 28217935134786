/* Languange EN */

var enCelebRegist = {
    //Form
    "celebRegist.form.title"      : "My Creator Profile",
    "celebRegist.form.subtitle"   : "We’d like to see if you already 🗣 have what it takes to be our verified BintanGO creators!",
    "celebRegist.form.name"       : "FULL NAME*",
    "celebRegist.form.email"      : "EMAIL*",
    "celebRegist.form.ig"         : "INSTAGRAM USERNAME*",
    "celebRegist.form.val"        : "Invalid IG username format, example : @example",
    "celebRegist.form.username"   : "USERNAME*",
    "celebRegist.form.privacy1"   : "By submitting this information you agree to the ",
    "celebRegist.form.privacy2"   : " and ",
    "celebRegist.form.privacy3"   : " as a BintanGO creator",
    "celebRegist.form.submit"     : "SUBMIT MY CREATOR PROFILE",
    "celebRegist.form.dob"        : "DOB",
    "celebRegist.form.gender"     : "GENDER",
    "celebRegist.form.genderM"    : "Male",
    "celebRegist.form.genderF"    : "Female",
    "celebRegist.form.phone"      : "PHONE NUMBER",
    "celebRegist.form.pg1Title"   : "Your Personal Information",

    //Step2
    "celebRegist.form.tiktok"     : "TIKTOK USERNAME",
    "celebRegist.form.youtube"    : "YOUTUBE CHANNEL",
    "celebRegist.form.twitter"    : "TWITTER USERNAME",
    "celebRegist.form.pg2Title"   : "Your Social Media",

    //Pop Up
    "celebRegist.popup.title"     : "Hooray!",
    "celebRegist.popup.subtitle"  : "Your BintanGO Creator account is ready! Start your journey by creating your own page to show your passion.",
    "celebRegist.popup.btnCreate" : "CREATE MY LINK",
    "celebRegist.popup.btnExplore": "EXPLORE",

    //Pop Up IG not Fount
    "celebRegist.popupIG.title"   : "We cannot find your IG",
    "celebRegist.popupIG.subTitle": "It looks like your instagram is not a business account .",
    "celebRegist.popupIG.subTitle2": "It looks like your instagram is not a business account, you will miss some of our features. But don't worry, you can still continue to register as a creator",

  };
  
export default enCelebRegist;