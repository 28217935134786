/* Languange EN */

var idDownloadApps = {
    /* 00.Example */
    "downloadApps.titleBanner"          : "Unduh aplikasi BintanGO dan temukan idola kamu",
    "downloadApps.titleDesc"            : "Kenal lebih dekat dengan selebriti dan kreator favoritmu sekarang juga",
    "downloadApps.videoCall"            : "Video Call",
    "downloadApps.videoCallDesc"        : "Dapatkan momen spesial untuk video call langsung dengan idolamu. Dengarkan langsung jawaban mereka dari pertanyaan yang selalu bikin kamu penasaran!",
    "downloadApps.vcChoose"             : "Pilih",
    "downloadApps.vcChooseDesc"         : "selebriti dan kreator favorit kamu",
    "downloadApps.vcSchedule"           : "Jadwalkan",
    "downloadApps.vcScheduleDesc"       : "sesi panggilanmu di kalender mereka!",
    "downloadApps.vcPay"                : "Bayar",
    "downloadApps.vcPayDesc"            : "pesananmu dengan mudah dan aman",
    "downloadApps.vcEnjoy"              : "Enjoy!",
    "downloadApps.vcEnjoyDesc"          : "Video call idolamu di applikasi BintanGO!",
    "downloadApps.videoShoutout"        : "Video Shoutout",
    "downloadApps.exclusive"            : "Eksklusif di App",
    "downloadApps.videoShoutoutDesc"    : "Dapatkan video shoutout dari idola khusus untuk kamu sekarang!",
    "downloadApps.vsChoose"             : "Pilih",
    "downloadApps.vsChooseDesc"         : "selebriti dan kreator favorit kamu",
    "downloadApps.vsRequest"            : "Pesan",
    "downloadApps.vsRequestDesc"        : "video shoutout untuk kamu atau temanmu",
    "downloadApps.vsPay"                : "Bayar",
    "downloadApps.vsPayDesc"            : "pesananmu dengan mudah dan aman",
    "downloadApps.vsDownload"           : "Unduh",
    "downloadApps.vsDownloadDesc"       : "dan bagikan video shoutout kamu dari idola",
    "downloadApps.celebrityPlatform"    : "Celebrity on Demand Platform",
  };
  
export default idDownloadApps;