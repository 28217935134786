/* Languange EN */

var enSearchCategory = {
  "searchCat.leftMenu.category"         : "Category",
  "searchCat.leftMenu.all"              : "Show All",
  "searchCat.leftMenu.allPrice"         : "All Price",

  //Sort
  "searchCat.leftMenu.sort"             : "Sort by",
  "searchCat.leftMenu.sortList1"        : "Most popular",
  "searchCat.leftMenu.sortList2"        : "High to low price",
  "searchCat.leftMenu.sortList3"        : "Low to high price",
  "searchCat.leftMenu.sortList4"        : "Alphabetical names A-Z",
  "searchCat.leftMenu.sortList5"        : "Alphabetical names Z-A",
  "searchCat.leftMenu.sortList6"        : "Number of Reviews",

  //Price
  "searchCat.leftMenu.price"             : "Price range",
  "searchCat.leftMenu.minprice"          : "Minimum Price",
  "searchCat.leftMenu.maxprice"          : "Maximum Price",
  "searchCat.leftMenu.0-250k"            : "IDR 0 - IDR 250,000",
  "searchCat.leftMenu.250k-500k"         : "IDR 250,001 - IDR 500,000",
  "searchCat.leftMenu.500k-1jt"          : "IDR 500,001 - IDR 1,000,000",
  "searchCat.leftMenu.1jt-5jt"           : "IDR 1,000,001 - IDR 5,000,000",
  "searchCat.leftMenu.5jt++"             : "> IDR 5,000,000",

  //Time
  "searchCat.leftMenu.time"             : "Execution Time",
  "searchCat.leftMenu.timeList1"        : "24 hours Delivery",
  "searchCat.leftMenu.timeList2"        : "1 - 3 Days",
  "searchCat.leftMenu.timeList3"        : "4 - 7 Days",
  "searchCat.leftMenu.timeList4"        : "8 - 14 Days",
  "searchCat.leftMenu.timeList5"        : "> 14 Days",

  //Banner
  "searchCat.leftMenu.banner1"        : "Surprise Birthday Wishes",
  "searchCat.leftMenu.banner2"        : "Get Advice from the Best Person",
  "searchCat.leftMenu.banner3"        : "Roasting your Friend",

  //Button
  "searchCat.button.reset"            : "Reset",
  "searchCat.button.filter"           : "Filter",

  //Title
  "searchCat.title"                   : "Search Results for ",
  
  //Search Not Found
  "searchCat.notFound.title"          : "Search Result",
  "searchCat.notFound.desc1"          : "Search not found",
  "searchCat.notFound.desc2"          : "But we have some celebrity recommendations that might be perfect for you",
};
  
export default enSearchCategory;