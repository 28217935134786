/* Languange ID */

var idBioManage = {
    
    "manageLink.addBlock"        : "+ TAMBAHKAN BLOK BARU",
    "manageLink.chooseBlock"     : "Pilih Tipe Blok",
    "manageLink.menuLink"        : "Link",
    "manageLink.menuText"        : "Teks",
    "manageLink.menuGallery"     : "Galeri",
    "manageLink.menuSocial"      : "Post Social Media",

    "manageLink.title"           : "JUDUL",
    "manageLink.urlLink"         : "URL LINK",
    "manageLink.emojiIcon"       : "IKON EMOJI",
    "manageLink.position"        : "POSISI",
    "manageLink.addLink"         : "TAMBAH LINK",
    "manageLink.updateLink"      : "UBAH LINK",
    "manageLink.left"            : "Kiri",
    "manageLink.right"           : "Kanan",
    "manageLink.both"            : "Kiri & Kanan",
    "manageLink.linkBlock"       : "Blok Link",

    "manageLink.text"            : "TEKS",
    "manageLink.addText"         : "TAMBAHKAN TEKS",
    "manageLink.updateText"      : "UBAH TEXT",
    "manageLink.textBlock"       : "Blok Teks",

    "manageLink.gallery"         : "NAMA GALERI",
    "manageLink.titleasHeader"   : "Nama galeri sebagai judul blok",
    "manageLink.titleasCaption"  : "Nama galeri sebagai keterangan blok",
    "manageLink.gallerymin3"     : "GALERI (MINIMAL 3 GAMBAR)",
    "manageLink.addNewGrid"      : "TAMBAH BARIS BARU",
    "manageLink.addGallery"      : "TAMBAHKAN GALERI",
    "manageLink.updateGallery"   : "UBAH GALERI",
    "manageLink.addImage"        : "Tambah Gambar",
    "manageLink.submit"          : "TAMBAHKAN",
    "manageLink.galleryBlock"    : "Blok Galeri",

    "manageLink.addSocial"       : "TAMBAHKAN LINK SOSIAL MEDIA",
    "manageLink.updateSocial"    : "UBAH LINK SOSIAL MEDIA",
    "manageLink.socialBlock"     : "Blok Post Social Media",

    "manageLink.confirmDelete"   : "Kami mau pastiin aja nih..",
    "manageLink.confirmDeleteBlock"  : "Apakah kamu yakin mau menghapus blok ini?",
    "manageLink.confirmDeleteGrid"  : "Apakah kamu yakin mau menghapus baris ini?",
    "manageLink.yes"             : "HAPUS",
    "manageLink.cancel"          : "BATAL",
  };
  
export default idBioManage;