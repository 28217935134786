/* Languange ID */

var idBioMyLink = {
    
    // Galeri
    "bioMyLink.header.validation"          : "tolong masukkan URL gambar",

    "myLink.edit"                          : "UBAH",
    "myLink.copy"                          : "SALIN",
    "myLink.design"                        : "GAYA",
    "myLink.mypage"                        : "BLOK SAYA",

    "myLink.changeUsername"                : "Ganti username",
    "myLink.usernameOnce"                  : "Username kamu hanya dapat diganti 1 kali",
    "myLink.usernameChange"                : "Username BintanGO kamu akan terganti",
    "myLink.usernameChange2"               : "URL Mylink kamu juga akan terganti",

  };
  
export default idBioMyLink;