/* Languange EN */

var enPopUpVoucher = {
    //General
    "popuVoucher.choose"        : "Choose",
    "popuVoucher.list.title"    : "Select Vouchers",
    "popuVoucher.list.input"    : "Search vouchers",
    "popuVoucher.list.apply"    : "APPLY",
    "popuVoucher.detail.title"  : "Voucher Detail",
    "popuVoucher.detail.copy"   : "Copy",
    "popuVoucher.detail.period" : "Promotion Period",
    "popuVoucher.detail.tc"     : "Terms and Condition",

    //Messages
    "popuVoucher.toast.copy"   : "Copied to clipboard",
  };
  
export default enPopUpVoucher;