/* Languange ID */

var idBioDesign = {
    
  // Header
  "bioDesign.header.title"            : "Header",
  "bioDesign.header.subtitle"         : "Tampilkan profil terbaik kamu",
  "bioDesign.header.ppTitle"          : "GANTI FOTO PROFIL",
  "bioDesign.header.shapeTitle"       : "Bentuk Foto Profil",
  "bioDesign.header.shapeBorder"      : "Tambahkan garis luar di gambar profil",
  "bioDesign.header.shapeBorderColor" : "Warna Garis Luar",
  "bioDesign.header.textColor"        : "Warna Teks",
  "bioDesign.header.textTitle"        : "Teks Header",
  "bioDesign.header.textSubTitle"     : "NAMA HEADER",
  "bioDesign.header.textBioTitle"     : "BIO",
  "bioDesign.header.textLocTitle"     : "LOKASI",
  "bioDesign.header.inputChar"        : "karakter",

  //Template
  "bioDesign.template.title"            : "Tema",
  "bioDesign.template.subtitle"         : "Ganti tampilan profilmu dengan tema pilihan kami",
  "bioDesign.template.blank"            : "Polos",
  "bioDesign.template.light"            : "Terang",
  "bioDesign.template.dark"             : "Gelap",
  
  //Block Style
  "bioDesign.block.title"            : "Desain Blok",
  "bioDesign.block.subtitle"         : "Ubah desain blok sesuai dengan personalita kamu",
  "bioDesign.block.sqbox"            : "Kotak Persegi",
  "bioDesign.block.sftcnr"           : "Sudut Membulat",
  "bioDesign.block.alround"          : "Bulat",
  "bioDesign.block.fwidth"           : "Penuh",
  "bioDesign.block.outline"          : "Tambahkan garis luar",
  "bioDesign.block.shadow"           : "Tambahkan efek bayangan",
  "bioDesign.block.clrBlock"         : "Blok",
  "bioDesign.block.clrBlockTxt"      : "Teks",
  "bioDesign.block.clrBlockLink"     : "Blok link",
  "bioDesign.block.clrIcon"          : "Icon",
  "bioDesign.block.clrOutline"       : "Garis Luar",
  "bioDesign.block.clrShadow"        : "Bayangan",

  //Background
  "bioDesign.bg.title"                : "Latar Belakang",
  "bioDesign.bg.subtitle"             : "Pilih latar belakang yang fantastis ",
  "bioDesign.bg.color"                : "Warna",
  "bioDesign.bg.colorDesc"            : "Warna Latar Belakang",
  "bioDesign.bg.image"                : "Image",
  "bioDesign.bg.imageSub1"            : "Photos by ",
  "bioDesign.bg.imageSub2"            : ". Search the same term again for different results.",
  "bioDesign.bg.imagePlaceHolder"     : "Cari Gambar",
  "bioDesign.bg.imageUrlTitle"        : "Tambahkan Gambar Custom",
  "bioDesign.bg.imageSubmit"          : "SUBMIT",
  "bioDesign.bg.imageUseBg"           : "TAMBAHKAN GAMBAR CUSTOM",
  "bioDesign.bg.imageChgUrlBg"        : "GANTI GAMBAR CUSTOM",
  // "bioDesign.bg.video"                : "Video",
  // "bioDesign.bg.videoPlaceHolder"     : "Search videos",
  "bioDesign.bg.validation"           : "tolong masukkan URL gambar",

  //Font
  "bioDesign.font.title"            : "Fonts",
  "bioDesign.font.subtitle"         : "Atur font untuk menggambarkan diri kamu",
  "bioDesign.font.choose"           : "PILIH FONT",

  //Social Media
  "bioDesign.sosmed.title"            : "Ikon Sosial Media",
  "bioDesign.sosmed.subtitle"         : "Tambahkan media sosial di profil kamu",
  "bioDesign.sosmed.addBtnList"       : "+ TAMBAH",
  "bioDesign.sosmed.addTitle"         : "Tambah Ikon Sosial Media",
  "bioDesign.sosmed.platformTitle"    : "PLATFORM",
  "bioDesign.sosmed.urlTitle"         : "URL LINK",
  "bioDesign.sosmed.addBtn"           : "TAMBAH SOSIAL MEDIA",
  "bioDesign.sosmed.updateBtn"        : "UBAH",
  "bioDesign.sosmed.deleteBtn"        : "HAPUS",
  "bioDesign.sosmed.cancelBtn"        : "BATAL",
  "bioDesign.sosmed.validateLink"     : "masukkan format URL yang benar",
  "bioDesign.sosmed.popUpDelTitle"    : "Kami mau pastiin aja nih..",
  "bioDesign.sosmed.popUpDelSubTitle" : "Apakah kamu yakin mau menghapus blok ini?",
  
  //Live Preview
  "bioDesign.footer.viewVer2m1"       : "Create something like this for free",
  "bioDesign.footer.viewVer2m2"       : "as BintanGO creator! Register now",
};

export default idBioDesign;