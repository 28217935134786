/* Languange ID */

var idLogin = {
    "login.form.title"           : "Masuk",
    "login.form.email"           : "EMAIL",
    "login.form.password"        : "PASSWORD",
    "login.form.button"          : "Masuk",
    "login.form.otherLoginText"  : "Atau masuk dengan",
    "login.form.signupText"      : "Belum punya akun?",
    "login.form.signupLink"      : "Daftar sekarang",
    "login.form.errButton"       : "Lanjut",
    "login.form.forgotPassword"  : "Lupa password",
    "login.form.error1"  : "Transaksi gagal. Username atau password yang Kamu masukkan tidak valid.",
  };
  
export default idLogin;