/* Languange ID */

var idVerify = {
    "verify.form.title"         : "Kode Verifikasi Telah Terkirim",
    "verify.form.reg1"        : "Email kode verifikasi telah terkirim ke ",
    "verify.form.forgot1"     : "Email kode verifikasi telah terkirim ke ",
    "verify.form.reg2"        : ", harap lakukan verifikasi untuk bisa melanjutkan registrasi.",
    "verify.form.forgot2"     : ", harap lakukan verifikasi untuk melakukan pergantian password.",
  };
  
export default idVerify;