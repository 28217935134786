/* Languange EN */

var idHome = {
    "home.download.title"        : "Unduh aplikasi BintanGO dan temukan idola kamu",
    "home.download.desc"         : "Kenal lebih dekat dengan selebriti dan kreator favoritmu sekarang juga",
    "home.latestCeleb"           : "Selebriti Baru",
    "home.videoCallList"         : "Video Call yang menantimu",

    "home.creatorTitle"          : "Start your journey as creator now!",
    "home.creatorDesc"           : "You will never be ready, just start now💪🏻. One more step to get your creator freedom with BintanGO",
    "home.iamCreator"            : "I AM CREATOR",
    "home.maybeLater"            : "NO. MAYBE LATER",
    "home.buttonCreator"         : "CREATOR? CLICK HERE",
  };
  
export default idHome;