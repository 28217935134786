/* Languange ID */

var idFAQ = {
    //Title
    "faq.page.title"            : "Pusat Bantuan",

    "home.static1.desc"         : "Download aplikasinya untuk menelusuri seleb idola kamu lebih lanjut dan dapatkan promo-promo menarik",
    "home.latestCeleb"          : "Latest Celebs",
  };
  
export default idFAQ;