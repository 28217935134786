/* Languange ID */

var enFinance = {
    "finance"                   : "Finance",
    "finance.backToHome"        : "Back to Creator Home",
    "finance.create"            : "Creator financing",
    "finance.earlyPay"          : "Early Pay",
    "finance.celebFinancing"    : "Celeb Financing",
    "finance.info"              : "Financing Info",
    "finance.headerContentTitle": "Still want to waste your time?",
    "finance.headerContentDesc" : "Use our financing feature to get paid quickly",
    "finance.headerContentTitleEarlyPay": "No more waiting time from your clients ⌛️",
    "finance.headerContentDescEarlyPay": "Don't fuzz too much, just make sure you have the file of this doc with you!",
    "finance.existTransactionTitle": "Disbursed Amount",
    "finance.existTransactionDesc": "How much money you have received faster than your client promise",
    "finance.financingContract" : "financing contracts",
    "finance.seeAllTransaction" : "See All Transaction",
    "finance.reqNewFinancing"   : "Request new financing",
    "finance.loanAmount"        : "Loan Amount",
    "finance.howToPay"          : "how to pay",
    "finance.repaymentMethod"   : "Repayment Method",
    "finance.dueDate"           : "Due Date",
    "finance.monthTenor"        : "Month Tenor",
    "finance.daysTenor"         : "Days Tenor",
    "finance.submissionDate"    : "Submission Date",
    "finance.rejectedDate"      : "Rejected Date",
    "finance.reason"            : "Reason",
    "finance.paymentFinish"     : "Payment Finish",
    "finance.noData"            : "No Data",
    "finance.copy"              : "COPY",

    "finance.howtopayWallet"    : "How to pay with BintanGO Wallet",
    "finance.titleHowtopayWallet": "BintanGO Wallet",
    "finance.descHowtopayWallet": "Automatically deduct the repayment amount from your BintanGO wallet on the due date.<br/><br/>So make sure your BintanGO Wallet is sufficient to make payments.",
    "finance.howtopayVA"        : "How to pay with Virtual Account",
    "finance.titleHowtopayVA"   : "BCA Virtual Account",
    "finance.descHowtopayVA"    : "Pay through Virtual Account when the repayment due date",
    "finance.atm1"              : "Insert the card into the <b>ATM machine</b>",
    "finance.atm2"              : "Enter your <b>6 digit PIN</b>",
    "finance.atm3"              : "Select <b>Others Transaction</b> and the <b>Select Transfer</b>",
    "finance.atm4"              : "Continue to <b>BCA Virtual Account</b> and  input the <b>BCA Virtual Account Number</b>",
    "finance.atm5"              : "Input the <b>ammount of the loans</b> and make sure the transaction details are correct",
    "finance.atm6"              : "Click <b>Yes</b>, and your payment has been completed",
    "finance.mobile1"           : "Please <b>login</b> to the <b>BCA Mobile application</b>",
    "finance.mobile2"           : "Select <b>m-Transfer</b>",
    "finance.mobile3"           : "Continue to <b>BCA Virtual Account</b> and  input the <b>BCA Virtual Account Number</b>",
    "finance.mobile4"           : "Input the <b>ammount of the loans</b> and make sure the transaction details are correct",
    "finance.mobile5"           : "Enter your <b>m-BCA Pin</b> and your transaction has been completed",

    "finance.sort"              : "Sort",
    "finance.sort.loanAmount"   : "Loan Amount",
    "finance.sort.lastUpdate"   : "Last Update",

    "finance.status"            : "Status",
    "finance.status.ongoing"    : "On Going",
    "finance.status.waiting"    : "Waiting",
    "finance.status.rejected"   : "Rejected",
    "finance.status.paymentFailed" : "Payment Failed",
    "finance.status.finished"   : "Finished",

    "finance.become"            : "This feature is exclusive for our verified creators! Apply now",
    "finance.becomeOneTitle"    : "Unlimited Access",
    "finance.becomeOneDesc"     : "Get access to all Creator Tools such as Invoice, Creator Mission, Financing, and MyLink to maximize your potential and become a Star!",
    "finance.becomeTwoTitle"    : "More Hustle!",
    "finance.becomeTwoDesc"     : "Opening more opportunities  by getting cooperation offers from various well-known brands",
    "finance.becomeThreeTitle"  : "Be the first one to know!",
    "finance.becomeThreeDesc"   : "For any new features from BintanGO. We promise to let you know first, and foremost.",

    "finance.howOneTitle"       : "Fill the data",
    "finance.howOneDesc"        : "Fill in all the required data on the Early Pay form",
    "finance.howTwoTitle"       : "Create or select invoice",
    "finance.howTwoDesc"        : "You can create your own invoice in BintanGO or you can just upload the invoice that you have. You can apply for financing up to 80% from invoice amount",
    "finance.howThreeTitle"     : "Wait for the result",
    "finance.howThreeDesc"      : "Wait for the result in 1 to 3 work day(s) whether the application was successfully accepted or not.",
    "finance.howFourTitle"      : "Receive it in Creator Wallet",
    "finance.howFourDesc"       : "When its approved, you'll receive it directly in your Creator Wallet. Just 5 working days the longest, no more waiting for months.",
    "finance.howFiveTitle"      : "Repay on time",
    "finance.howFiveDesc"       : "Client has paid? Go repay the financing and get more jobs!",

    "finance.whyOneTitle"       : "Faster Payment",
    "finance.whyOneDesc"        : "Faster invoice disbursement without waiting for the contract deadline",
    "finance.whyTwoTitle"       : "<30 Seconds to claim",
    "finance.whyTwoDesc"        : "It's very easy to claim funding with a very fast process",
    "finance.whyThreeTitle"     : "Guaranteed to be safe!",
    "finance.whyThreeDesc"      : "Your safety will be maintained with partners who have been supervised by OJK",
    "finance.benefitsOneTitle"  : "No need to wait anymore",
    "finance.benefitsOneDesc"   : "Now no need to waste time anymore, because you can get payment from your invoice directly",
    "finance.benefitsTwoTitle"  : "Access for everything",
    "finance.benefitsTwoDesc"   : "No need to bother re-registering again, just three steps and you can access it",
    "finance.benefitsThreeTitle": "Claim multiple invoices",
    "finance.benefitsThreeDesc" : "Not only 1/2/3 Invoice, but all invoices can be cashed at the same time now",
    "finance.why"               : "Why",
    "finance.not"               : "Not",
    "finance.creatorFinancing"  : "Creator Financing",
    "finance.buttonVerify"      : "VERIFY ME NOW",
    "finance.buttonApply"       : "APPLY NOW",
    "finance.next"              : "NEXT",
    "finance.prev"              : "PREVIOUS",
    "finance.select"            : "SELECT",
    "finance.ktp"               : "KTP",
    "finance.yourInvoice"       : "Your Invoice",
    "finance.benefits"          : "Benefits",
    "finance.how"               : "How its work",
    "finance.activity"          : "Loan Activity",
    "finance.finish"            : "Finish Loan",
    "finance.transaction"       : "Transaction History",
    "finance.ActivityNoDataDesc": "It seems that you don't have a completed loan yet",

    "finance.oppss"             : "Oppss",
    "finance.createInvoiceFirstDesc" : "Seems you have not created any invoice from BintanGO yet! Start billing your client through us!",
    "finance.createInvoiceFirstButton" : "CREATE YOUR FIRST INVOICE",

    //Step
    "finance.step1.navTitle"    : "Step 1",
    "finance.step1.navSubTitle" : "Fill in your data",
    "finance.step1.name"        : "FULL NAME *",
    "finance.step1.id"          : "ID NUMBER *",
    "finance.step1.email"       : "EMAIL ADDRESS *",
    "finance.step1.dob"         : "DOB *",
    "finance.step1.gender"      : "GENDER *",
    "finance.step1.phone"       : "PHONE NUMBER *",
    "finance.step1.address"     : "ADDRESS *",
    "finance.step1.idcard"      : "UPLOAD ID CARD",
    "finance.step1.idcardPopUp" : "Upload ID Card",
    "finance.step1.step1PopUp"  : "Make sure the position of the ID card is in the middle of the frame and the data on the ID card is visible.",
    "finance.step1.step2PopUp"  : "Make sure the ID card is still valid",
    "finance.step1.step3PopUp"  : "Make sure the ID card is not a scan or photocopied.",
    
    "finance.step2.navTitle"        : "Step 2",
    "finance.step2.navSubTitle"     : "Financing Info",
    "finance.step2.invAmt"          : "INVOICE AMOUNT *",
    "finance.step2.loanAmt"         : "LOAN AMOUNT *",
    "finance.step2.tenor"           : "TENOR",
    "finance.step2.tenorSub"        : "Make sure you pick tenor longer than your client due date",
    "finance.step2.method"          : "LOAN REPAYMENT METHOD",
    "finance.step2.upload"          : "Upload or select invoice",
    "finance.step2.seeSelect"       : "See selection",
    "finance.step2.select1"         : "You have selected ",
    "finance.step2.select2"         : " invoices",
    "finance.step2.selected"        : "Your selected invoice",
    "finance.step2.optVaTitle"      : "BintaGO Wallet",
    "finance.step2.optVaSub"        : "Automatically deduct the repayment amount from your BintanGO wallet on the due date.",
    "finance.step2.optWaTitle"      : "Virtual Account",
    "finance.step2.optWaSub"        : "Pay through Virtual Account when the repayment due date",
    "finance.step2.invPopupTitle"   : "Select Invoice",
    "finance.step2.invPopupSubTitle": "Only showing unpaid invoice with Terms of Payment from 30 to 90 days",
    "finance.step2.invPopupDesc"    : "You can select more than 1 invoice",

    "finance.step3.navTitle"    : "Step 3",
    "finance.step3.navSubTitle" : "Choose your limit",
    "finance.step3.clientName"  : "CLIENT NAME *",
    "finance.step3.contact"     : "CONTACT PIC *",
    "finance.step3.phone"       : "PIC PHONE NUMBER",
    "finance.step3.email"       : "EMAIL ADDRESS *",

    "finance.success.title"     : "Your application has been successfully sent! ",
    "finance.success.subtitle"  : "We will inform you once your application is approved. Make sure notification setting is turned on.",
    "finance.success.home"      : "SEE MY FINANCING HISTORY",
    "finance.success.history"   : "BACK TO CREATOR MENU",
};
  
export default enFinance;