/* Languange EN */

var enDownloadApps = {
    /* 00.Example */
    "downloadApps.titleBanner"          : "Download BintanGO app and meet your idol!",
    "downloadApps.titleDesc"            : "Meet your favorite celebrities and creators in your hand",
    "downloadApps.videoCall"            : "Video Call",
    "downloadApps.videoCallDesc"        : "Have a special moment with your idol through a video call session. Find the answer to your burning curiosity!",
    "downloadApps.vcChoose"             : "Choose",
    "downloadApps.vcChooseDesc"         : "your favorite celebrity and creator",
    "downloadApps.vcSchedule"           : "Schedule",
    "downloadApps.vcScheduleDesc"       : "your session on their busy calendar!",
    "downloadApps.vcPay"                : "Pay",
    "downloadApps.vcPayDesc"            : "your order safe and easy",
    "downloadApps.vcEnjoy"              : "Enjoy!",
    "downloadApps.vcEnjoyDesc"          : "Video call with your idol directly on our app!",
    "downloadApps.videoShoutout"        : "Video Shoutout",
    "downloadApps.exclusive"            : "Exclusive on App",
    "downloadApps.videoShoutoutDesc"    : "Get a personalized shoutout from your idol now!",
    "downloadApps.vsChoose"             : "Choose",
    "downloadApps.vsChooseDesc"         : "your favorite celebrity and creator",
    "downloadApps.vsRequest"            : "Request",
    "downloadApps.vsRequestDesc"        : "personalized video shoutout for you or your loved ones!",
    "downloadApps.vsPay"                : "Pay",
    "downloadApps.vsPayDesc"            : "your order safe and easy",
    "downloadApps.vsDownload"           : "Download",
    "downloadApps.vsDownloadDesc"       : "and share your video shoutout from your idol",
    "downloadApps.celebrityPlatform"    : "Celebrity on Demand Platform",
  };
  
export default enDownloadApps;