import appLocaleData from 'react-intl/locale-data/en';
import enUS from '../locales/enUS';
import enFooter from '../locales/element/enFooter';
import enNavBar from '../locales/element/enNavBar';
import enPopUpLogin from '../locales/element/enPopUpLogin';
import enSearch from '../locales/element/enSearch';
import enPopUpVoucher from '../locales/element/enPopUpVoucher';
import enPopUpNotifApps from '../locales/element/enPopUpNotifApps';
import enPopUpPurchaseStep from '../locales/element/enPopUpPurchaseStep';
import enHome from '../locales/pages/enHome';
import enHomeMVP from '../locales/pages/enHomeMVP';
import enDetailArtist from '../locales/pages/enDetailArtist';
import enSearchCategory from '../locales/pages/enSearchCategory';
import enRegister from '../locales/pages/enRegister';
import enLogin from '../locales/pages/enLogin';
import enVerify from '../locales/pages/enVerify';
import enForgotPassword from '../locales/pages/enForgotPassword';
import enPrivacy from '../locales/pages/enPrivacy';
import enTermCondition from '../locales/pages/enTermCondition';
import enBizzInquiry from '../locales/pages/enBizzInquiry';
import enOrder from '../locales/pages/enOrder';
import enDashboard from '../locales/pages/enDashboard';
import enFAQ from '../locales/pages/enFAQ';
import enWithdraw from '../locales/pages/enWithdraw';
import enDownloadApps from '../locales/pages/enDownloadApps';
import enBioDesign from '../locales/pages/enBioDesign';
import enBioManage from '../locales/pages/enBioManage';
import enBioMyLink from '../locales/pages/enBioMyLink';
import enInvoiceStep from '../locales/pages/enInvoiceStep';
import enInvoice from '../locales/pages/enInvoice';
import enFinance from '../locales/pages/enFinance';
import enBrand from '../locales/pages/enBrand';
import enMission from '../locales/pages/enMission';
import enCelebRegist from '../locales/pages/enCelebRegist';
import enWallet from '../locales/pages/enWallet';
import enAnalytics from '../locales/pages/enAnalytics';

const EnLang = {
    messages: {
        ...enUS,
        ...enFooter,
        ...enNavBar,
        ...enHome,
        ...enHomeMVP,
        ...enDetailArtist,
        ...enSearchCategory,
        ...enPopUpLogin,
        ...enSearch,
        ...enRegister,
        ...enLogin,
        ...enVerify,
        ...enForgotPassword,
        ...enPrivacy,
        ...enTermCondition,
        ...enBizzInquiry,
        ...enOrder,
        ...enDashboard,
        ...enFAQ,
        ...enWithdraw,
        ...enPopUpVoucher,
        ...enPopUpNotifApps,
        ...enDownloadApps,
        ...enPopUpPurchaseStep,
        ...enBioDesign,
        ...enBioManage,
        ...enBioMyLink,
        ...enInvoiceStep,
        ...enInvoice,
        ...enFinance,
        ...enBrand,
        ...enMission,
        ...enCelebRegist,
        ...enWallet,
        ...enAnalytics,
    },
    locale: 'en-US',
    data: appLocaleData
};
export default EnLang;